import { forwardRef, useCallback } from "react";
import { CUSTOMERS_ROLE_VALUE_OWNER } from "../../../constants/customers";
import generateApiUrl from "../../../libraries/utils/generateApiUrl";
import Autocomplete from "../Autocomplete";

/**
 * Determine le name à afficher
 * @param {object} param0
 * @param {import("../../../types/UserCustomerAutocomplete").UserCustomerAutocomplete} param0.user
 * @returns {string}
 */
function getName({ user }) {
  let name = "";

  if (user?.first_name) {
    name = user.first_name;
  }

  if (user?.last_name) {
    name = name ? `${name} ${user.last_name}` : user.last_name;
  }

  if (user?.company_name) {
    name = name ? `${name} (${user.company_name})` : user.company_name;
  }

  return name;
}

export const CustomersAutocompleteMultiple = forwardRef(
  /**
   * @typedef {object} Props
   * @property {string[]} [environmentId]
   * @property {object} [queryParams]
   * @property {keyof typeof import("../../../constants/customers").CUSTOMERS_ROLES} [role]
   */
  /**
   * @param {Props & import("../Autocomplete").AutocompleteCommonProps<string[]>} props
   */
  function CustomersAutocompleteMultiple(
    {
      environmentId = [],
      queryParams = {},
      role = CUSTOMERS_ROLE_VALUE_OWNER,
      ...otherProps
    },
    ref,
  ) {
    const resolveItemValue = useCallback(({ item }) => String(item?.id), []);

    const resolveItemName = useCallback(
      ({ item }) => getName({ user: item }),
      [],
    );

    const generateUrl = useCallback(
      ({ page, fields }) =>
        generateApiUrl({
          id: "@customers.autocomplete",
          query: {
            role,
            page,
            per_page: 100,
            environment_id: environmentId,
            ...fields,
            ...queryParams,
          },
        }),
      [environmentId, queryParams, role],
    );

    const generateHydrationUrl = useCallback(
      ({ valuesToHydrate }) =>
        generateApiUrl({
          id: "@customers.autocomplete",
          query: {
            only_id: valuesToHydrate,
          },
        }),
      [],
    );

    return (
      <Autocomplete
        ref={ref}
        generateUrl={generateUrl}
        generateHydrationUrl={generateHydrationUrl}
        resolveItemValue={resolveItemValue}
        resolveItemName={resolveItemName}
        isMultiple={true}
        {...otherProps}
      />
    );
  },
);
