import {
  Tag,
  TagLabel,
  TagLeftIcon,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  RESOURCES_STATES,
  RESOURCES_STATES_MESSAGE,
} from "../../../constants/resources";
import capitalize from "@splitfire-agency/raiden-library/dist/libraries/utils/capitalize";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

/**
 * @param {{
 * state: string,
 * message?: any,
 * states: {[key: symbol]: {value: any, label: any, colors?: string[], color: string, icon?: any}},
 * whiteSpace?: "inherit" | "initial" | "unset" | "normal" | "nowrap" | "pre" | "pre-line" | "pre-wrap" | "break-spaces" | "revert" | "unset" | "initial" | "inherit" | "initial" | "inherit",
 * }} props
 * @returns
 */
export default function ResourceState(props) {
  const { state, message, states, whiteSpace = "nowrap" } = props;

  const intl = useIntl();

  const localState = states ?? RESOURCES_STATES;

  let label = "";

  if (message) {
    label = intl.formatMessage(message, {
      state: state,
    });
  } else if (states) {
    label = intl.formatMessage(states?.[state]?.label);
  } else {
    label = intl.formatMessage(message ?? RESOURCES_STATES_MESSAGE, {
      state: state,
    });
  }

  const color = useColorModeValue(
    localState?.[state]?.colors?.[0],
    localState?.[state]?.colors?.[1],
  );

  const { icon: Icon } = localState?.[state] || {
    icon: null,
  };

  return (
    <Tag
      size="lg"
      borderRadius="full"
      borderWidth="0.0625rem"
      borderColor={color}>
      {Icon && <TagLeftIcon color={color} boxSize="1.25rem" as={Icon} />}

      <TagLabel whiteSpace={whiteSpace} color={color}>
        {capitalize(label)}
      </TagLabel>
    </Tag>
  );
}

ResourceState.propTypes = {
  state: PropTypes.string,
  message: PropTypes.any,
};

ResourceState.defaultProps = {};
