import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Code,
  Flex,
  HStack,
  Text,
  Tooltip,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import ResourceState from "@raiden/library-ui/components/Resource/State";
import {
  TASKS_STATES,
  TASKS_VISIBILITIES,
} from "@raiden/library-ui/constants/tasks";
import {
  getTaskProgress,
  getTaskProgressLabel,
} from "@raiden/library-ui/helpers/tasks";
import Table from "@splitfire-agency/raiden-library/dist/components/Table";
import { FormattedMessage, useIntl } from "react-intl";

/**
 * @typedef Props
 * @property {import("@raiden/library-ui/types/Task").Task} resource
 * @returns {import("react").FunctionComponentElement<Props>}
 */
export function InfoTable({ resource }) {
  const intl = useIntl();

  const borderColor = useColorModeValue("gray.200", "gray.600");

  const responsive = useBreakpointValue({
    base: true,
    md: false,
  });

  return (
    <Flex gap="1rem" flexDir="column">
      <Box p="1rem" border="1px" borderColor={borderColor}>
        <HStack mb="1rem">
          <Text flexGrow={1} fontSize="1.125rem" fontWeight="bold">
            {intl.formatMessage({
              defaultMessage: "Informations générales",
            })}
          </Text>
        </HStack>

        <Table variant="striped2" layout={responsive ? "column" : "row"}>
          <Table.Tbody>
            <Table.Tr>
              <Table.Td>
                {intl.formatMessage({
                  defaultMessage: "ID",
                })}
              </Table.Td>

              <Table.Td>
                <Code>{resource.id}</Code>
              </Table.Td>
            </Table.Tr>

            <Table.Tr>
              <Table.Td>
                {intl.formatMessage({
                  defaultMessage: "Type",
                })}
              </Table.Td>

              <Table.Td>
                <Text>
                  {resource.type_label ? (
                    <Text>{resource.type_label}</Text>
                  ) : (
                    <Text>
                      {intl.formatMessage({ defaultMessage: "Tâche inconnue" })}
                    </Text>
                  )}
                </Text>
              </Table.Td>
            </Table.Tr>

            <Table.Tr>
              <Table.Td>
                {intl.formatMessage({
                  defaultMessage: "Etat",
                })}
              </Table.Td>

              <Table.Td>
                <ResourceState state={resource.state} states={TASKS_STATES} />
              </Table.Td>
            </Table.Tr>

            <Table.Tr>
              <Table.Td>
                {intl.formatMessage({
                  defaultMessage: "Visibilité",
                })}
              </Table.Td>

              <Table.Td>
                <ResourceState
                  state={resource.visibility}
                  states={TASKS_VISIBILITIES}
                />
              </Table.Td>
            </Table.Tr>

            <Table.Tr>
              <Table.Td>
                {intl.formatMessage({
                  defaultMessage: "Progression",
                })}
              </Table.Td>

              {resource.progress !== null && resource.max_progress !== null ? (
                <Table.Td>
                  <Tooltip
                    label={`${resource.progress}/${resource.max_progress}`}
                    placement="bottom-start">
                    <Box cursor="pointer" w="min-content" borderRadius="full">
                      <CircularProgress
                        value={getTaskProgress({ task: resource })}
                        color={TASKS_STATES[resource.state]?.colors[0]}>
                        <CircularProgressLabel>
                          {getTaskProgressLabel({ task: resource })}
                        </CircularProgressLabel>
                      </CircularProgress>
                    </Box>
                  </Tooltip>
                </Table.Td>
              ) : (
                <Table.Td>
                  <Text>
                    {intl.formatMessage({ defaultMessage: "Non renseigné" })}
                  </Text>
                </Table.Td>
              )}
            </Table.Tr>

            {resource.issuer && (
              <Table.Tr>
                <Table.Td>
                  {intl.formatMessage({
                    defaultMessage: "Créée par",
                  })}
                </Table.Td>

                <Table.Td>{resource.issuer?.display_name}</Table.Td>
              </Table.Tr>
            )}

            {resource.attempts && (
              <Table.Tr>
                <Table.Td>
                  {intl.formatMessage({
                    defaultMessage: "Nombre de tentatives",
                  })}
                </Table.Td>

                <Table.Td>{resource.attempts}</Table.Td>
              </Table.Tr>
            )}

            {resource.max_attempts && (
              <Table.Tr>
                <Table.Td>
                  {intl.formatMessage({
                    defaultMessage: "Nombre max de tentatives",
                  })}
                </Table.Td>

                <Table.Td>{resource.max_attempts}</Table.Td>
              </Table.Tr>
            )}

            {resource.created_at && (
              <Table.Tr>
                <Table.Td>
                  {intl.formatMessage({
                    defaultMessage: "Date d’ajout",
                  })}
                </Table.Td>

                <Table.Td>
                  <FormattedMessage
                    defaultMessage="Le {date} à {heure}"
                    values={{
                      date: intl.formatDate(resource.created_at, {
                        dateStyle: "short",
                      }),
                      heure: intl.formatDate(resource.created_at, {
                        timeStyle: "short",
                      }),
                    }}
                  />
                </Table.Td>
              </Table.Tr>
            )}

            {resource.started_at && (
              <Table.Tr>
                <Table.Td>
                  {intl.formatMessage({
                    defaultMessage: "Date de début",
                  })}
                </Table.Td>

                <Table.Td>
                  <FormattedMessage
                    defaultMessage="Le {date} à {heure}"
                    values={{
                      date: intl.formatDate(resource.started_at, {
                        dateStyle: "short",
                      }),
                      heure: intl.formatDate(resource.started_at, {
                        timeStyle: "short",
                      }),
                    }}
                  />
                </Table.Td>
              </Table.Tr>
            )}

            {resource.updated_at && (
              <Table.Tr>
                <Table.Td>
                  {intl.formatMessage({
                    defaultMessage: "Date de mise à jour",
                  })}
                </Table.Td>

                <Table.Td>
                  <FormattedMessage
                    defaultMessage="Le {date} à {heure}"
                    values={{
                      date: intl.formatDate(resource.updated_at, {
                        dateStyle: "short",
                      }),
                      heure: intl.formatDate(resource.updated_at, {
                        timeStyle: "short",
                      }),
                    }}
                  />
                </Table.Td>
              </Table.Tr>
            )}

            {resource.deleted_at && (
              <Table.Tr>
                <Table.Td>
                  {intl.formatMessage({
                    defaultMessage: "Date de suppression",
                  })}
                </Table.Td>

                <Table.Td>
                  <FormattedMessage
                    defaultMessage="Le {date} à {heure}"
                    values={{
                      date: intl.formatDate(resource.deleted_at, {
                        dateStyle: "short",
                      }),
                      heure: intl.formatDate(resource.deleted_at, {
                        timeStyle: "short",
                      }),
                    }}
                  />
                </Table.Td>
              </Table.Tr>
            )}
          </Table.Tbody>
        </Table>
      </Box>
    </Flex>
  );
}
