import { useDisclosure } from "@chakra-ui/react";
import { POLICIES_POLICY_VALUE_VIEW } from "@raiden/library-ui/constants/policies";
import useCanPerform from "@raiden/library-ui/hooks/useCanPerform";
import { View } from "../View";

/**
 * @callback childrenRender
 * @param {{disclosure: import("@chakra-ui/react").UseDisclosureReturn}} disclosure
 */

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/Task").Task} resource
 * @property {() => void} mutate
 * @property {childrenRender} children
 */
/**
 * @param {Props} props
 * @returns {import("react").FunctionComponentElement<Props>}
 */
export function TasksActionsView(props) {
  const { resource, children, mutate } = props;

  const disclosure = useDisclosure();

  const { isOpen, onClose } = disclosure;

  const canPerform = useCanPerform();

  const filePolicies = resource?.meta?.policies ?? [];

  return (
    <>
      {canPerform({
        policies: POLICIES_POLICY_VALUE_VIEW,
        resourcePolicies: filePolicies,
      }) && (
        <>
          {isOpen && (
            <View
              resource={resource}
              isOpen={isOpen}
              onClose={onClose}
              mutate={mutate}
            />
          )}

          {children({ disclosure })}
        </>
      )}
    </>
  );
}
