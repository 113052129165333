// deps
import { useIntl } from "react-intl";
import { Icon, IconButton } from "@chakra-ui/react";
import { IoSearch } from "react-icons/io5";
import { useSearch } from "@raiden/library-ui/contexts/Search";

/**
 * @typedef {object} Props
 */

/**
 * @param {Props} props
 * @returns {import("react").FunctionComponentElement<Props>}
 */
function SearchCommonSubmit(props) {
  const intl = useIntl();

  const { form, preventSubmitIfNotDirty, response } = useSearch();

  return (
    <IconButton
      aria-label={intl.formatMessage({
        defaultMessage: "Soumettre",
      })}
      isDisabled={preventSubmitIfNotDirty && !form.formState.isDirty}
      colorScheme="brandPrimary"
      alignSelf="flex-end"
      type="submit"
      icon={<Icon as={IoSearch} />}
      isLoading={response.isValidating}
      {...props}
    />
  );
}

export default SearchCommonSubmit;
