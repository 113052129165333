// deps
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import Router, { useRouter } from "next/router";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useIntl } from "react-intl";

// containers
import SignedInFooter from "./Footer";
import SignedInHeader from "./Header";
import Menu from "./Menu";

// components
import MaintenanceSplash from "../../components/MaintenanceSplash";

// hooks
import { useMaintenanceMode } from "@raiden/library-ui/contexts/MaintenanceMode";
import useAuth from "@raiden/library-ui/hooks/useAuth";

// libraries
import generateAdminPath from "@raiden/library-ui/libraries/utils/generateAdminPath";

// contextsuseMaintenanceMode } from "@raiden/library-ui/contexts/MaintenanceMode";

// constants
import { USERS_USER_TYPE_VALUE_ADMIN } from "@raiden/library-ui/constants/users";
import { MENU_ITEMS } from "../../constants/menu";
import { WIDTH_MENU } from "../../constants/theme";

export default function SignedInLayout(props) {
  const { children } = props;

  const intl = useIntl();

  const isMobileLayout =
    useBreakpointValue(
      {
        base: true,
        lg: false,
      },
      "lg",
    ) ?? false;

  const {
    isOpen: isOpenMenu,
    onClose: onCloseMenu,
    onToggle: onToggleMenu,
  } = useDisclosure();

  const router = useRouter();

  useEffect(() => {
    const handler = () => {
      onCloseMenu();
    };
    router.events.on("routeChangeStart", handler);
    return () => {
      router.events.off("routeChangeStart", handler);
    };
  }, [onCloseMenu, router.events]);

  const bgColor = useColorModeValue("white", "gray.800");

  const { logged, loading, userAdmin } = useAuth();

  useEffect(
    function () {
      if (
        !loading &&
        (!logged || USERS_USER_TYPE_VALUE_ADMIN !== userAdmin?.user_type)
      ) {
        Router.push({
          pathname: generateAdminPath({ id: "login" }),
          query: {
            next: Router.asPath,
          },
        });
      }
    },
    [logged, loading, userAdmin?.user_type],
  );

  const { isOpen: maintenanceModeIsOpen, onClose: maintenanceModeOnClose } =
    useMaintenanceMode();

  return (
    <>
      <Modal
        isOpen={maintenanceModeIsOpen}
        size="xl"
        onClose={maintenanceModeOnClose}>
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalHeader>
            {intl.formatMessage({
              defaultMessage: "Maintenance en cours",
            })}
          </ModalHeader>

          <ModalBody>
            <MaintenanceSplash
              addon={
                <Button onClick={maintenanceModeOnClose}>
                  {intl.formatMessage({ defaultMessage: "Fermer" })}
                </Button>
              }
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Flex h="full" flexDir="column" pos="relative">
        <SignedInHeader onClickMenu={onToggleMenu} />

        <Flex flexGrow={1} position="relative" mt="4rem">
          <Flex flexGrow={1} flexDir="column" bgColor={bgColor} minW="0">
            <Box
              flexGrow={1}
              display="flex"
              flexDir="column"
              ml={isMobileLayout ? "0rem" : WIDTH_MENU}
              transition="margin .25s ease-in-out">
              {children}
            </Box>
            <SignedInFooter />
          </Flex>
          <Box flexShrink={0}>
            <Menu
              menuItems={MENU_ITEMS}
              isOpened={isOpenMenu}
              onClose={onCloseMenu}
              isMobileLayout={isMobileLayout}
            />
          </Box>
        </Flex>
      </Flex>
    </>
  );
}

SignedInLayout.propTypes = {
  children: PropTypes.node,
};
