import { forwardRef, useCallback } from "react";
import generateApiUrl from "../../../libraries/utils/generateApiUrl";
import Autocomplete from "../Autocomplete";

/**
 * Determine le name à afficher
 * @param {object} param0
 * @param {import("../../../types/InsuranceAutocomplete").InsuranceAutocomplete} param0.insurance
 * @returns {string}
 */
function getLabel({ insurance }) {
  let label = "";

  if (insurance?.name) {
    label = insurance.name;
  }

  return label;
}

export const InsurancesAutocompleteMultiple = forwardRef(
  /**
   * @typedef {object} Props
   * @property {object} [queryParams]
   */
  /**
   * @param {Props & import("../Autocomplete").AutocompleteCommonProps<string>} props
   */
  function InsurancesAutocompleteMultiple(
    { queryParams = {}, ...otherProps },
    ref,
  ) {
    const resolveItemValue = useCallback(({ item }) => String(item?.id), []);

    const resolveItemName = useCallback(
      ({ item }) => getLabel({ insurance: item }),
      [],
    );

    const generateUrl = useCallback(
      ({ page, fields }) =>
        generateApiUrl({
          id: "@insurances.autocomplete",
          query: {
            page,
            per_page: 20,
            ...fields,
            ...queryParams,
          },
        }),
      [queryParams],
    );

    const generateHydrationUrl = useCallback(
      ({ valuesToHydrate }) =>
        generateApiUrl({
          id: "@insurances.autocomplete",
          query: {
            only_id: valuesToHydrate,
          },
        }),
      [],
    );

    return (
      <Autocomplete
        ref={ref}
        generateUrl={generateUrl}
        generateHydrationUrl={generateHydrationUrl}
        resolveItemValue={resolveItemValue}
        resolveItemName={resolveItemName}
        isMultiple={true}
        {...otherProps}
      />
    );
  },
);
