import {
  AspectRatio,
  Box,
  Button,
  HStack,
  Icon,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { forwardRef } from "react";
import { MdCheck } from "react-icons/md";
import PampaImage from "../../PampaImage";

export const Option = forwardRef(
  /**
   * @param {import("../Autocomplete").AutocompleteRenderOptionParams} props
   */
  function Option(
    { value, item, itemValue, name, toggleValue, focusPrev, focusNext },
    ref,
  ) {
    const hloColor = useColorModeValue("gray.500", "gray.200");

    return (
      <Box mt=".5rem" _first={{ mt: 0 }}>
        <Button
          ref={ref}
          onClick={() => {
            toggleValue({ value: itemValue, item });
          }}
          onKeyDown={(event) => {
            if (event.key === "ArrowUp") {
              event.preventDefault();
              focusPrev();
            }
            if (event.key === "ArrowDown") {
              event.preventDefault();
              focusNext();
            }
          }}
          alignItems="center"
          justifyContent="flex-start"
          variant="ghost"
          size="sm"
          pl="1rem"
          pr="1rem"
          w="100%"
          textAlign="left"
          height="auto">
          <HStack maxW="100%" overflow="hidden">
            <Box flexShrink={0}>
              <AspectRatio ratio={1} w="50px">
                <PampaImage
                  image={item?.picture}
                  mode="crop"
                  format="square"
                  maxContainerWidth={50}
                  alt=""
                />
              </AspectRatio>
            </Box>
            <Stack spacing=".25rem" overflow="hidden">
              <Text>{item?.name}</Text>

              <Text color={hloColor} fontWeight="400">
                {item?.hlo}
              </Text>
            </Stack>
          </HStack>
          {(value === itemValue ||
            (Array.isArray(value) && value?.includes?.(itemValue))) && (
            <HStack pl=".5rem" ml="auto">
              <Icon as={MdCheck} />
            </HStack>
          )}
        </Button>
      </Box>
    );
  },
);
