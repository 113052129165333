import { Button, Icon, Tag } from "@chakra-ui/react";
import { MdOutlineTimelapse } from "react-icons/md";
import { useIntl } from "react-intl";
import { TasksActionsViewAny } from "../../../containers/Tasks/Actions/ViewAny";
import { useTaskDrawer } from "../../../hooks/useTaskDrawer";

const MAX_NUMBER_OF_TASKS_TO_DISPLAY = 9;

export function TasksButton() {
  const { onTaskDrawerOpen, numberOfStartedTasks } = useTaskDrawer();

  const intl = useIntl();

  return (
    <TasksActionsViewAny>
      <Button
        position="relative"
        size="md"
        onClick={() => onTaskDrawerOpen({ fields: {} })}
        marginRight=".5rem"
        variant="outline"
        colorScheme="brandPrimary"
        aria-label={intl.formatMessage({
          defaultMessage: "Voir les tâches",
        })}>
        <Icon as={MdOutlineTimelapse} />

        <Tag
          variant="solid"
          opacity="1"
          background="brandPrimary.500"
          position="absolute"
          borderRadius="full"
          top="-10px"
          right="-15px">
          {numberOfStartedTasks > MAX_NUMBER_OF_TASKS_TO_DISPLAY
            ? "9+"
            : numberOfStartedTasks}
        </Tag>
      </Button>
    </TasksActionsViewAny>
  );
}
