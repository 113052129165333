// deps
import PropTypes from "prop-types";
import { WrapItem } from "@chakra-ui/react";

// components
import EnvironmentSelect from "../../EnvironmentSelectWithAuthorizations";
import FormControlRHF from "@raiden/library-ui/components/ReactHookForm/FormControlRHF";
import { usePreferences } from "@raiden/library-ui/contexts/Preferences";

/**
 * @typedef {object} Props
 * @property {boolean} [withEnvironments]
 * @property {string} [environmentPlaceholder]
 * @property {boolean} [shouldBypassRenderEnvironments]
 * @property {boolean} [withMultipleEnvironments]
 * @property {string | string[]} [authorizations]
 * @property {import("@chakra-ui/react").FormLabelProps & {[key: string]: any}} [labelProps]
 * @property {string} [label]
 * @property {string} [name]
 */
/**
 * @param {Props} props
 */
export default function EnvironmentSelectRHF(props) {
  const {
    withEnvironments = true,
    environmentPlaceholder,
    shouldBypassRenderEnvironments = false,
    withMultipleEnvironments = true,
    authorizations,
    labelProps,
    label,
    name = "environment_id",
  } = props;

  const { shouldRenderEnvironments } = usePreferences();

  if (
    withEnvironments &&
    (shouldRenderEnvironments || shouldBypassRenderEnvironments)
  ) {
    return (
      <WrapItem>
        <FormControlRHF
          labelProps={labelProps}
          name={name}
          label={label}
          placeholder={environmentPlaceholder}
          renderWithFormControl={(field) => (
            <EnvironmentSelect
              {...field}
              placeholder={environmentPlaceholder}
              authorizations={authorizations}
              isMultiple={withMultipleEnvironments}
            />
          )}
        />
      </WrapItem>
    );
  }

  return null;
}

EnvironmentSelectRHF.propTypes = {
  withEnvironments: PropTypes.bool,
  environmentPlaceholder: PropTypes.string,
  shouldBypassRenderEnvironments: PropTypes.bool,
  withMultipleEnvironments: PropTypes.bool,
  authorizations: PropTypes.array,
  labelProps: PropTypes.any,
  label: PropTypes.string,
  name: PropTypes.string,
};
