import { forwardRef, useCallback } from "react";
import generateApiUrl from "../../../libraries/utils/generateApiUrl";
import Autocomplete from "../Autocomplete";
import { Option } from "./Option";

function getRoomOption(params) {
  return <Option {...params} />;
}

export const RoomsAutocompleteMultiple = forwardRef(
  /**
   * @typedef {object} Props
   * @property {string} [customerId]
   * @property {string} [withAuthorization]
   * @property {boolean} [onlyOwner]
   * @property {object} [queryParams]
   */
  /**
   * @param {Props & import("../Autocomplete").AutocompleteCommonProps<string[]>} props
   */
  function RoomsAutocompleteMultiple(
    {
      customerId,
      withAuthorization,
      onlyOwner,
      queryParams = {},
      ...otherProps
    },
    ref,
  ) {
    const resolveItemValue = useCallback(({ item }) => String(item?.id), []);

    const resolveItemName = useCallback(
      ({ item }) =>
        [item.hlo, item.name].filter((value) => Boolean(value)).join(" - "),
      [],
    );

    const generateUrl = useCallback(
      ({ page, fields }) =>
        generateApiUrl({
          id: "@hotelsRooms.autocomplete",
          query: {
            page,
            per_page: 20,
            customer_id: customerId,
            with_authorization: withAuthorization,
            only_owner: onlyOwner,
            ...fields,
            ...queryParams,
          },
        }),
      [customerId, onlyOwner, queryParams, withAuthorization],
    );

    const generateHydrationUrl = useCallback(
      ({ valuesToHydrate }) =>
        generateApiUrl({
          id: "@hotelsRooms.autocomplete",
          query: {
            only_id: valuesToHydrate,
          },
        }),
      [],
    );

    return (
      <Autocomplete
        ref={ref}
        generateUrl={generateUrl}
        generateHydrationUrl={generateHydrationUrl}
        resolveItemValue={resolveItemValue}
        resolveItemName={resolveItemName}
        isMultiple={true}
        renderOption={getRoomOption}
        {...otherProps}
      />
    );
  },
);
