// deps
import PropTypes from "prop-types";
import { WrapItem } from "@chakra-ui/react";

// components
import SubscriptionSelect from "../../SubscriptionSelect";
import FormControlRHF from "@raiden/library-ui/components/ReactHookForm/FormControlRHF";

// hooks
import useCanPerform from "@raiden/library-ui/hooks/useCanPerform";

// constants
import { AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_VIEW_ANY } from "@raiden/library-ui/constants/authorizations";

/**
 * @typedef {object} Rules
 * @property {boolean} [required]
 * @property {number} [maxLength]
 * @property {number} [minLength]
 * @property {number} [max]
 * @property {number} [min]
 * @property {RegExp} [pattern]
 * @property {(value: any) => boolean | Promise<boolean> | string} [validate]
 * @property {boolean} [valueAsNumber]
 * @property {boolean} [valueAsDate]
 * @property {(value: any) => any} [setValueAs]
 * @property {boolean} [disabled]
 * @property {(e: import("react").SyntheticEvent) => void} [onChange]
 * @property {(e: import("react").SyntheticEvent) => void} [onBlur]
 * @property {any} [value]
 */

/**
 * @typedef {object} Props
 * @property {string} [placeholder]
 * @property {import("@chakra-ui/react").FormLabelProps & {[key: string]: any}} [labelProps]
 * @property {string} [label]
 * @property {string} [name]
 * @property {any} [helperText]
 * @property {Rules} [rules]
 * @property {boolean} [withSubscriptions]
 */
/**
 * @param {Props} props
 */
export default function SubscriptionsSelectRHF(props) {
  const {
    withSubscriptions = true,
    placeholder,
    labelProps,
    label,
    name = "subscription_id",
    rules,
    helperText,
  } = props;

  const canPerform = useCanPerform();

  const can = canPerform({
    authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_VIEW_ANY,
  });

  if (can && withSubscriptions) {
    return (
      <WrapItem>
        <FormControlRHF
          labelProps={labelProps}
          name={name}
          label={label}
          placeholder={placeholder}
          rules={rules}
          renderWithFormControl={(field) => (
            <SubscriptionSelect
              {...field}
              helperText={helperText}
              placeholder={placeholder}
            />
          )}
        />
      </WrapItem>
    );
  }

  return null;
}

SubscriptionsSelectRHF.propTypes = {
  placeholder: PropTypes.string,
  labelProps: PropTypes.any,
  label: PropTypes.string,
  name: PropTypes.string,
  rules: PropTypes.object,
};
