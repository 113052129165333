// deps
import { Select, FormHelperText } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { forwardRef } from "react";

// context
import { useConfiguration } from "@raiden/library-ui/hooks/useConfiguration";

const SubscriptionsSelect = forwardRef(
  /**
   * @typedef Props
   * @property {string} name
   * @property {string} value
   * @property {string} [placeholder]
   * @property {object} [label]
   * @property {boolean} [isDisabled]
   * @property {any} [helperText]
   * @property {(data: any) => void} [onChange]
   * @property {(data: any) => void} [onChangeLabel]
   */

  /**
   * @param {Props} props
   * @returns {import("react").FunctionComponentElement<Props> | null}
   */
  function SubscriptionsSelect(props, ref) {
    const {
      isDisabled = false,
      placeholder,
      helperText,
      ...otherProps
    } = props;

    const intl = useIntl();

    const {
      configuration: { subscriptions },
    } = useConfiguration();

    if (!subscriptions) {
      return null;
    }

    return (
      <>
        <Select
          {...otherProps}
          isDisabled={isDisabled}
          placeholder={
            placeholder ??
            intl.formatMessage({
              defaultMessage: "Toutes",
            })
          }
          ref={ref}>
          {(subscriptions ?? []).map(function (subscription) {
            return (
              <option key={subscription.id} value={String(subscription.id)}>
                {subscription.name}
              </option>
            );
          })}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </>
    );
  },
);

export default SubscriptionsSelect;
