import { forwardRef, useCallback } from "react";
import generateApiUrl from "../../../libraries/utils/generateApiUrl";
import Autocomplete from "../Autocomplete";

/**
 * Determine le name à afficher
 * @param {object} param0
 * @param {import("../../../types/RegionAutocomplete").RegionAutocomplete} param0.region
 * @returns {string}
 */
function getLabel({ region }) {
  let label = "";

  if (region?.name) {
    label = region.name;
  }

  return label;
}

export const RegionsAutocompleteMultiple = forwardRef(
  /**
   * @typedef {object} Props
   * @property {object} [queryParams]
   */
  /**
   * @param {Props & import("../Autocomplete").AutocompleteCommonProps<string>} props
   */
  function RegionsAutocomplete({ queryParams = {}, ...otherProps }, ref) {
    const resolveItemValue = useCallback(({ item }) => String(item?.id), []);

    const resolveItemName = useCallback(
      ({ item }) => getLabel({ region: item }),
      [],
    );

    const generateUrl = useCallback(
      ({ page, fields }) =>
        generateApiUrl({
          id: "@regions.autocomplete",
          query: {
            page,
            per_page: 100,
            ...fields,
            ...queryParams,
          },
        }),
      [queryParams],
    );

    const generateHydrationUrl = useCallback(
      ({ valuesToHydrate }) =>
        generateApiUrl({
          id: "@regions.autocomplete",
          query: {
            only_id: valuesToHydrate,
          },
        }),
      [],
    );

    return (
      <Autocomplete
        ref={ref}
        generateUrl={generateUrl}
        generateHydrationUrl={generateHydrationUrl}
        resolveItemValue={resolveItemValue}
        resolveItemName={resolveItemName}
        isMultiple={true}
        {...otherProps}
      />
    );
  },
);
