// deps
import { SimpleGrid, Button } from "@chakra-ui/react";
import { useIntl } from "react-intl";

// components

// contexts
import { useSearch } from ".";

function SearchInfinitePagination(props) {
  const { buttonProps } = props;

  const { form, response, onSubmit } = useSearch();

  const intl = useIntl();

  const responseTotal = response.data?.meta?.total;
  const responsePage = response.data?.meta?.current_page;
  const responseLastPage = response.data?.meta?.last_page;

  return (
    <>
      {responseTotal && (
        <SimpleGrid columns={2} spacing="1rem">
          <Button
            {...buttonProps}
            onClick={function () {
              form.setValue("page", responsePage - 1);
              onSubmit();
            }}
            isDisabled={responsePage === 1}>
            {intl.formatMessage({ defaultMessage: "Précédent" })}
          </Button>
          <Button
            {...buttonProps}
            onClick={function () {
              form.setValue("page", responsePage + 1);
              onSubmit();
            }}
            isDisabled={responsePage === responseLastPage}>
            {intl.formatMessage({ defaultMessage: "Suivant" })}
          </Button>
        </SimpleGrid>
      )}
    </>
  );
}

export default SearchInfinitePagination;
