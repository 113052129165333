// deps
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

// components
import ResourceLoading from "@splitfire-agency/raiden-library/dist/components/Resource/Loading";
import Error from "../../components/Error";

// contexts
import { useShow } from ".";

export default function ShowData(props) {
  const { data, error, isValidating } = useShow();

  const intl = useIntl();

  if (!error && data?.data && !isValidating) {
    if (typeof props?.data === "function") {
      return props.data();
    }
    return props.data;
  }

  if (isValidating) {
    return (
      <ResourceLoading
        p="1rem"
        label={intl.formatMessage({
          id: "raiden.admin.contexts.Show.Data.texts.loading.title",
          defaultMessage: "Chargement de la ressource…",
        })}
      />
    );
  }

  return <Error.Global type="view" error={error} />;
}

ShowData.propTypes = {
  data: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};
