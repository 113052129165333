// components
import InjectSearchId from "./InjectSearchId";

/**
 * @template C
 * @param {React.FunctionComponent<C>} Component
 * @returns {React.FunctionComponent<C>}
 */
export default function injectSearchId(Component) {
  /**
   * @typedef {object} Props
   * @property {boolean} [injectSearchId]
   */
  /**
   * @param {Props & import("react").PropsWithChildren<C>} props
   */
  function SearchId(props) {
    const { injectSearchId = true } = props;

    if (injectSearchId) {
      return (
        <InjectSearchId>
          <Component {...props} />
        </InjectSearchId>
      );
    }

    return <Component {...props} />;
  }
  return SearchId;
}
