// utils
import blacklist from "@splitfire-agency/raiden-library/dist/libraries/utils/blacklist";

/**
 * Implémentation basic de l’envoi des paramètres en query dans l’URL.
 * @param {object} param0
 * @param {object} param0.fields
 */
export default function defaultPushToUrl({ fields }) {
  const pushableFields = blacklist(Object.keys(fields), {});

  const query = [];

  for (const fieldName of pushableFields) {
    query[fieldName] = fields[fieldName];
  }

  return query;
}
