// deps
import PropTypes from "prop-types";
import { Select } from "@chakra-ui/react";
import { useIntl } from "react-intl";

// components
import SelectMultiple from "@splitfire-agency/raiden-library/dist/components/SelectMultiple";

/**
 * @typedef _Props
 * @property {boolean} [isMultiple]
 * @property {boolean} [isDisabled]
 * @property {string} [placeholder]
 * @property {boolean} [includeWildcard]
 * @property {string} [labelWildcard]
 * @property {import("@raiden/library-ui/types/Configuration").ConfigurationEnvironment[]} [environments]
 * @property {any} [forwardedRef]
 */

/**
 * @typedef {_Props & import("@chakra-ui/react").SelectProps} Props
 */

/**
 * @param {Props} props
 * @returns {import("react").FunctionComponentElement<Props> | null}
 */
export default function EnvironmentSelect(props) {
  const {
    isMultiple = false,
    isDisabled = false,
    placeholder,
    includeWildcard,
    labelWildcard,
    environments,
    forwardedRef,
    ...otherProps
  } = props;

  const intl = useIntl();

  if (environments?.length === 0) {
    return null;
  }

  return (
    <>
      {isMultiple ? (
        <SelectMultiple
          {...otherProps}
          listMaxHeight={500}
          isDisabled={isDisabled}
          placeholder={
            placeholder ??
            intl.formatMessage({
              id: "raiden.admin.components.EnvironmentSelect.texts.placeholder",
              defaultMessage: "Environnement",
            })
          }
          label={function ({ count }) {
            return placeholder
              ? intl.formatMessage(
                  {
                    id: "raiden.admin.components.EnvironmentSelect.texts.label.withPlaceholder",
                    defaultMessage:
                      "{count, plural, =0 {{placeholder}} one {un environnement} other {# environnements}}",
                  },
                  { count, placeholder },
                )
              : intl.formatMessage(
                  {
                    id: "raiden.admin.components.EnvironmentSelect.texts.label.withoutPlaceholder",
                    defaultMessage:
                      "{count, plural, =0 {aucun environnement} one {un environnement} other {# environnements}}",
                  },
                  { count },
                );
          }}>
          {includeWildcard && (
            <option value="*">
              {labelWildcard ??
                intl.formatMessage({
                  defaultMessage: "Tout environnement confondu",
                })}
            </option>
          )}
          {(environments ?? []).map(function (environment) {
            return (
              <option key={environment.id} value={String(environment.id)}>
                {environment.name}
              </option>
            );
          })}
        </SelectMultiple>
      ) : (
        <Select
          {...otherProps}
          isDisabled={isDisabled}
          placeholder={
            placeholder ??
            intl.formatMessage({
              id: "raiden.admin.components.EnvironmentSelect.texts.placeholder",
              defaultMessage: "Environnement",
            })
          }
          ref={forwardedRef}>
          <>
            {includeWildcard && (
              <option key="*" value="*">
                {labelWildcard ??
                  intl.formatMessage({
                    defaultMessage: "Tout environnement confondu",
                  })}
              </option>
            )}
            {(environments ?? []).map(function (environment) {
              return (
                <option key={environment.id} value={environment.id}>
                  {environment.name}
                </option>
              );
            })}
          </>
        </Select>
      )}
    </>
  );
}

EnvironmentSelect.propTypes = {
  includeWildcard: PropTypes.bool,
  placeholder: PropTypes.string,
  isMultiple: PropTypes.bool,
  isDisabled: PropTypes.bool,
  authorizations: PropTypes.string,
  forwardedRef: PropTypes.any,
  labelWildcard: PropTypes.string,
};
