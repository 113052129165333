import { forwardRef, useCallback } from "react";
import generateApiUrl from "../../../libraries/utils/generateApiUrl";
import Autocomplete from "../Autocomplete";

/**
 * Determine le name à afficher
 * @param {object} param0
 * @param {import("../../../types/UserAdminAutocomplete").UserAdminAutocomplete} [param0.user]
 * @returns {string}
 */
function getName({ user }) {
  let name = "";

  if (user?.first_name) {
    name = user.first_name;
  }

  if (user?.last_name) {
    name = name ? `${name} ${user.last_name}` : user.last_name;
  }

  return name;
}

export const UsersAdminAutocomplete = forwardRef(
  /**
   * @typedef {object} Props
   * @property {object} [queryParams]
   */

  /**
   * @param {Props & import("../Autocomplete").AutocompleteCommonProps<string>} props
   */
  function UsersAdminAutocomplete({ queryParams = {}, ...otherProps }, ref) {
    const resolveItemValue = useCallback(({ item }) => String(item?.id), []);

    const resolveItemName = useCallback(
      ({ item }) => getName({ user: item }),
      [],
    );

    const generateUrl = useCallback(
      ({ page, fields }) =>
        generateApiUrl({
          id: "@usersAdmins.autocomplete",
          query: {
            page,
            per_page: 100,
            ...fields,
            ...queryParams,
          },
        }),
      [queryParams],
    );

    const generateHydrationUrl = useCallback(
      ({ valuesToHydrate }) =>
        generateApiUrl({
          id: "@usersAdmins.autocomplete",
          query: {
            only_id: valuesToHydrate,
          },
        }),
      [],
    );

    return (
      <Autocomplete
        ref={ref}
        generateUrl={generateUrl}
        generateHydrationUrl={generateHydrationUrl}
        resolveItemValue={resolveItemValue}
        resolveItemName={resolveItemName}
        isMultiple={false}
        {...otherProps}
      />
    );
  },
);
