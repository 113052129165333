import { Flex, VStack, Wrap, WrapItem } from "@chakra-ui/react";
import FormControlRHF from "@raiden/library-ui/components/ReactHookForm/FormControlRHF";
import {
  tasksStatesMessage,
  tasksTypesMessage,
  TASKS_SORT_LIST,
  TASKS_SORT_MESSAGE,
  TASKS_STATE_LIST,
  TASKS_TYPES_LIST,
} from "@raiden/library-ui/constants/tasks";
import { useSearch } from "@raiden/library-ui/contexts/Search";
import SelectMultiple from "@splitfire-agency/raiden-library/dist/components/SelectMultiple";
import { memo } from "react";
import { useIntl } from "react-intl";
import SearchCommonFilterListRHF from "../../../components/Search/CommonFilterListRHF";
import SearchCommonResetAndTag from "../../../components/Search/CommonResetAndTag";
import SearchCommonSortListRHF from "../../../components/Search/CommonSortListRHF";
import SearchCommonSubmit from "../../../components/Search/CommonSubmit";

/**
 * @typedef {{}} Props
 * @type {import("react").NamedExoticComponent<import("react").PropsWithChildren<Props>>}
 */
export const TaskDrawerSearchFilters = memo(function FilesSearchFilters() {
  const intl = useIntl();

  const { onSubmit, searchId } = useSearch();

  return (
    <VStack
      as="form"
      spacing="1.5rem"
      alignItems="flex-start"
      onSubmit={onSubmit}>
      <Flex w="full" justifyContent="space-between">
        <Wrap spacing="0.625rem" direction="row">
          <SearchCommonFilterListRHF
            withEnvironments={true}
            environmentPlaceholder={intl.formatMessage({
              defaultMessage: "Tous",
            })}
          />

          <WrapItem>
            <FormControlRHF
              name="type"
              label={intl.formatMessage({
                defaultMessage: "Type",
              })}
              labelProps={{ "data-search-id": searchId }}
              renderWithFormControl={(field) => (
                <SelectMultiple {...field}>
                  {TASKS_TYPES_LIST.map((type) => (
                    <option key={type.id} value={type.id}>
                      {intl.formatMessage(tasksTypesMessage, {
                        type: type.id.split("\\").join(""),
                      })}
                    </option>
                  ))}
                </SelectMultiple>
              )}
            />
          </WrapItem>

          <WrapItem>
            <FormControlRHF
              name="state"
              label={intl.formatMessage({
                defaultMessage: "État",
              })}
              labelProps={{ "data-search-id": searchId }}
              renderWithFormControl={(field) => (
                <SelectMultiple {...field}>
                  {TASKS_STATE_LIST.map((state) => (
                    <option key={state.id} value={state.id}>
                      {intl.formatMessage(tasksStatesMessage, {
                        state: state.id,
                      })}
                    </option>
                  ))}
                </SelectMultiple>
              )}
            />
          </WrapItem>

          <WrapItem>
            <SearchCommonSubmit />
          </WrapItem>
        </Wrap>

        <SearchCommonSortListRHF>
          {TASKS_SORT_LIST.map((tasksSort) => (
            <option key={tasksSort.id} value={tasksSort.id}>
              {intl.formatMessage(TASKS_SORT_MESSAGE, {
                sort: tasksSort.id,
              })}
            </option>
          ))}
        </SearchCommonSortListRHF>
      </Flex>

      <SearchCommonResetAndTag />
    </VStack>
  );
});
