// deps
import { useIntl } from "react-intl";
import { Button, Wrap, Icon } from "@chakra-ui/react";
import { IoRefreshOutline } from "react-icons/io5";

// contexts
import { SearchTags, useSearch } from "@raiden/library-ui/contexts/Search";

/**
 * @typedef {object} Props
 */

/**
 * @param {Props} props
 * @returns {import("react").FunctionComponentElement<Props>}
 */
function SearchCommonResetAndTag(props) {
  const intl = useIntl();

  const { onSubmit, reset } = useSearch();
  /**
   * Gère la réinitialisation des champs.
   */
  function handleReset() {
    reset();
    onSubmit();
  }

  return (
    <Wrap spacing="0.625rem" align="center">
      <Button flexShrink={0} size="sm" variant="outline" onClick={handleReset}>
        <Icon as={IoRefreshOutline} />
        &nbsp;
        {intl.formatMessage({
          defaultMessage: "Réinitialiser les filtres",
        })}
      </Button>

      <SearchTags />
    </Wrap>
  );
}

export default SearchCommonResetAndTag;
