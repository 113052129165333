/* istanbul ignore file */

// deps
import PropTypes from "prop-types";
import { Box, Text, VStack } from "@chakra-ui/react";
import { useIntl } from "react-intl";

// components
import ResourceLoading from "@splitfire-agency/raiden-library/dist/components/Resource/Loading";
import Splash from "../../components/Splash";
import Error from "../../components/Error";

// contexts
import { useSearch } from ".";

/**
 * @typedef {object} Props
 * @property {Function} renderData
 * @property {Function} [renderUnset]
 */

/**
 * @param {import("react").PropsWithChildren<Props>} props
 * @returns {import("react").FunctionComponentElement<Props>}
 */
export default function SearchData(props) {
  const { renderData, renderUnset } = props;

  const intl = useIntl();

  const {
    form,
    response: { data, error },
  } = useSearch();

  if (!form.formState.isValid) {
    return (
      <Box>
        <VStack spacing="1rem">
          <Text fontWeight="bold">
            {intl.formatMessage({
              defaultMessage: "Informations manquantes",
            })}
          </Text>

          <Text color="gray.500">
            {intl.formatMessage({
              defaultMessage: "Un champ obligatoire n’a pas été rempli.",
            })}
          </Text>
        </VStack>
      </Box>
    );
  }
  if (data && !error) {
    if (Array.isArray(data?.data)) {
      if (data?.data?.length > 0) {
        return renderData();
      }
    } else if (
      "object" === typeof data?.data &&
      null !== data?.data &&
      Object.keys(data?.data).length > 0
    ) {
      return renderData();
    }
    if (renderUnset) {
      return renderUnset();
    }
    return (
      <Box>
        <VStack spacing="1rem">
          <Splash
            title={intl.formatMessage({
              defaultMessage: "Pas de résultats",
            })}
            description={intl.formatMessage({
              defaultMessage:
                "Aucun résultat trouvé, essayez d’élargir votre recherche.",
            })}
            image="empty"
          />
        </VStack>
      </Box>
    );
  } else if (!data && !error) {
    return (
      <ResourceLoading
        p="1rem"
        label={intl.formatMessage({
          defaultMessage: "Chargement des résultats…",
        })}
      />
    );
  }

  return <Error.Global type="viewAny" error={error} />;
}

SearchData.propTypes = {
  renderData: PropTypes.func,
  renderUnset: PropTypes.func,
};
