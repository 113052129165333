import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import Splash from "@raiden/library-ui/components/Splash";
import { tasksTypesMessage } from "@raiden/library-ui/constants/tasks";
import useApiFetcher from "@raiden/library-ui/hooks/useApiFetcher";
import generateApiUrl from "@raiden/library-ui/libraries/utils/generateApiUrl";
import useForm from "@splitfire-agency/raiden-library/dist/hooks/useForm";
import { useState } from "react";
import { useIntl } from "react-intl";

/**
 * @typedef Props
 * @property {import("@raiden/library-ui/types/Task").Task} task
 * @property {boolean} isOpen
 * @property {() => void} onClose
 * @property {() => void} onSuccess
 */
/**
 * @param {Props} props
 * @returns {import("react").FunctionComponentElement<Props>}
 */
export function TaskRetry(props) {
  const { task, isOpen, onClose, onSuccess } = props;

  const intl = useIntl();

  const apiFetcher = useApiFetcher();

  const toast = useToast();

  const colorText = useColorModeValue("gray.700", "white.700");
  const colorDescription = useColorModeValue("gray.500", "white.500");

  const [taskCancelResponse, setFilesTaskCancel] = useState({
    loading: false,
  });

  const form = useForm({ onSubmit: handleSubmit });

  /**
   * Gère la soumission du formulaire.
   */
  function handleSubmit() {
    setFilesTaskCancel({ loading: true });

    apiFetcher(
      generateApiUrl({
        id: "@api.tasks.retry",
        parameters: {
          taskId: task?.id,
        },
        query: {},
      }),
      {
        method: "POST",
      },
    ).then(({ status }) => {
      toast({
        status: "success",

        title: intl.formatMessage(
          {
            defaultMessage: "La tâche « {name} » a été relancée avec succès !",
          },
          {
            name: intl.formatMessage(
              tasksTypesMessage,

              { type: task.type.split("\\").join("") },
            ),
          },
        ),
        duration: 5000,
        isClosable: true,
      });

      onSuccess();
    });
  }

  const isLoading = taskCancelResponse.loading;

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        blockScrollOnMount={false}>
        <ModalOverlay />

        <ModalContent as="form" onSubmit={form.onSubmit}>
          <ModalHeader color={colorText} fontSize="xl">
            {intl.formatMessage({
              defaultMessage: "Relancer la tâche",
            })}
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody textAlign="center">
            <Splash
              image="choice"
              title={intl.formatMessage({
                defaultMessage: "Relancer la tâche",
              })}
              description={
                <Text color={colorDescription}>
                  {intl.formatMessage(
                    {
                      defaultMessage:
                        "Êtes-vous sûr de vouloir relancer la tâche « {name} » ?",
                    },
                    {
                      name: intl.formatMessage(
                        tasksTypesMessage,

                        { type: task.type.split("\\").join("") },
                      ),
                    },
                  )}
                </Text>
              }
            />
          </ModalBody>

          <ModalFooter>
            <HStack spacing=".5rem">
              <Button
                colorScheme="gray"
                isDisabled={isLoading}
                onClick={onClose}
                variant="outline"
                size="sm">
                {intl.formatMessage({
                  defaultMessage: "Fermer",
                })}
              </Button>

              <Button
                type="submit"
                colorScheme="green"
                isLoading={isLoading}
                size="sm">
                {intl.formatMessage({
                  defaultMessage: "Réesayer",
                })}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
