import { useDisclosure } from "@chakra-ui/react";
import { POLICIES_POLICY_VALUE_CANCEL } from "@raiden/library-ui/constants/policies";
import useCanPerform from "@raiden/library-ui/hooks/useCanPerform";
import { TaskCancel } from "../Cancel";

/**
 * @callback childrenRender
 * @param {{disclosure: import("@chakra-ui/react").UseDisclosureReturn}} disclosure
 */

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/Task").Task} resource
 * @property {() => void} onSuccess
 * @property {childrenRender} children
 */
/**
 * @param {Props} props
 * @returns {import("react").FunctionComponentElement<Props>}
 */
export function TasksActionsCancel(props) {
  const { resource, onSuccess, children } = props;

  const disclosure = useDisclosure();

  const { isOpen, onClose } = disclosure;

  /**
   * Gère le succès de l'annulation d'une tâche
   */
  function handleSuccess() {
    onClose();
    onSuccess();
  }

  const canPerform = useCanPerform();

  const taskPolicy = resource?.meta?.policies ?? [];

  return (
    <>
      {canPerform({
        policies: POLICIES_POLICY_VALUE_CANCEL,
        resourcePolicies: taskPolicy,
      }) && (
        <>
          <TaskCancel
            task={resource}
            isOpen={isOpen}
            onClose={onClose}
            onSuccess={handleSuccess}
          />

          {children({ disclosure })}
        </>
      )}
    </>
  );
}
