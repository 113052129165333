// deps
import { Link, MenuItem, MenuList } from "@chakra-ui/react";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { useIntl } from "react-intl";

// hooks
import useAuth from "@raiden/library-ui/hooks/useAuth";
import useCanPerform from "@raiden/library-ui/hooks/useCanPerform";

// libraries
import generateAdminPath from "@raiden/library-ui/libraries/utils/generateAdminPath";

// constants
import { AUTHORIZATIONS_AUTHORIZATION_VALUE_EXTERNAL_KIOSC } from "@raiden/library-ui/constants/authorizations";
import useRequest from "@raiden/library-ui/hooks/useRequest";

export default function UserMenu() {
  const { logout, mutate } = useAuth();

  const intl = useIntl();

  const router = useRouter();

  const canPerform = useCanPerform();

  const { toastSuccess } = useRequest();

  /**
   * Gère le clic sur le bouton de déconnexion
   */
  function handleClickLogout() {
    logout().then(function () {
      toastSuccess({
        title: intl.formatMessage({
          defaultMessage: "Déconnexion réussie",
        }),
      });

      router.push({ pathname: generateAdminPath({ id: "login" }) });

      mutate();
    });
  }

  return (
    <MenuList>
      <NextLink
        href={generateAdminPath({ id: "@usersAdmins.me" })}
        passHref={true}>
        <MenuItem as="a" color="white">
          {intl.formatMessage({
            defaultMessage: "Mon compte",
          })}
        </MenuItem>
      </NextLink>

      <Link isExternal href="https://nomade.clevacances.com/identification">
        <MenuItem color="white">
          {intl.formatMessage({
            defaultMessage: "Nomade label",
          })}
        </MenuItem>
      </Link>

      <Link isExternal href="https://classement.clevacances.com/">
        <MenuItem color="white">
          {intl.formatMessage({
            defaultMessage: "Nomade classement",
          })}
        </MenuItem>
      </Link>

      <Link isExternal href="https://wiki.clevacances.com/">
        <MenuItem color="white">
          {intl.formatMessage({
            defaultMessage: "Wiki",
          })}
        </MenuItem>
      </Link>

      <Link isExternal href=" https://reseau.clevacances.com/FR/">
        <MenuItem color="white">
          {intl.formatMessage({
            defaultMessage: "Le réseau",
          })}
        </MenuItem>
      </Link>

      {canPerform({
        authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_EXTERNAL_KIOSC,
      }) && (
        <Link isExternal href={"https://cleva.kiosc.com/login"}>
          <MenuItem color="white">
            {intl.formatMessage({
              defaultMessage: "Kiosc",
            })}
          </MenuItem>
        </Link>
      )}

      <MenuItem onClick={handleClickLogout} color="red.500">
        {intl.formatMessage({
          defaultMessage: "Me déconnecter",
        })}
      </MenuItem>
    </MenuList>
  );
}
