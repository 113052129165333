// deps
import { createContext } from "react";

/**
 * @template [T=any]
 * @typedef {object} SearchContextValue
 * @property {string} searchId the value to use inject into the `data-search-id` attributes of labels
 * @property {any} tagsRefreshId a references that changes when the tags should be refreshed
 * @property {import("react").Dispatch<import("react").SetStateAction<{refreshInterval: number}>>} setSwrOptions
 * @property {import("swr").SWRConfiguration} swrOptions
 * @property {Array<number>} displayedPerPages
 * @property {false | ((params: {fields: Record<string, any>}) => string)} paginationUrl
 * @property {boolean} preventSubmitIfNotDirty
 * @property {import("react-hook-form").UseFormReturn} form
 * @property {object} submittedFields
 * @property {import("swr").SWRResponse<import("../../types/Api/ApiResponse").ApiResponse<T, object>>} response
 * @property {() => void} onSubmit
 * @property {() => void} updateTags
 * @property {() => void} reset
 * @property {object} defaultValues
 * @property {string[]} appliedFilters
 * @property {false|Function} routerSyncUrl
 */

/** @type {SearchContextValue<any>} */
export const SEARCH_CONTEXT_DEFAULT_VALUE = {
  searchId: "",
  tagsRefreshId: null,
  setSwrOptions: () => {},
  swrOptions: { refreshInterval: 0 },
  displayedPerPages: [],
  paginationUrl: false,
  preventSubmitIfNotDirty: false,
  // @ts-ignore ignored because it can't be mocked
  form: {},
  submittedFields: {},
  response: {
    data: undefined,
    error: undefined,
    isValidating: false,
    isLoading: false,
    // @ts-ignore ignored because it can't be mocked
    mutate: () => {},
  },
  onSubmit: () => {},
  updateTags: () => {},
  reset: () => {},
  defaultValues: {},
  appliedFilters: [],
  routerSyncUrl: false,
};

/**
 * @type {import("react").Context<SearchContextValue>}
 */
const SearchContext = createContext(SEARCH_CONTEXT_DEFAULT_VALUE);

export default SearchContext;
