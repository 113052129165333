import { ShowProvider } from "@raiden/library-ui/contexts/Show";
import generateApiUrl from "@raiden/library-ui/libraries/utils/generateApiUrl";
import { Data } from "./Data";

function endpointUrl(resource) {
  return generateApiUrl({
    id: "@api.tasks.view",
    parameters: {
      taskId: resource?.id,
    },
    query: {
      fields: ["task.policies", "task.object", "task.issuer"],
    },
  });
}

/**
 * @typedef Props
 * @property {import("@raiden/library-ui/types/Task").Task} resource
 * @property {boolean} isOpen
 * @property {() => void} onClose
 * @property {() => void} mutate
 */
/**
 * @param {import("react").PropsWithChildren<Props>} props
 * @returns {import("react").FunctionComponentElement<Props>}
 */
export function View({ resource, isOpen, onClose, mutate }) {
  return (
    <ShowProvider endpointUrl={endpointUrl(resource)}>
      <Data isOpen={isOpen} onClose={onClose} mutateSearch={mutate} />
    </ShowProvider>
  );
}
