import { DarkMode, LightMode } from "@chakra-ui/react";
import { usePreferences } from "../../contexts/Preferences";

export default function ResetColorMode({ children }) {
  const { globalColorMode } = usePreferences();

  const Mode = globalColorMode === "dark" ? DarkMode : LightMode;

  return <Mode>{children}</Mode>;
}
