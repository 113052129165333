/**
 * @param {object} params
 * @param {import("../../../types/Task").Task} params.task
 * @returns {number}
 */
export default function getTaskProgress({ task }) {
  if (task.progress !== 0 && task.max_progress !== 0) {
    const progress = Math.round((task.progress / task.max_progress) * 100);
    return progress;
  } else {
    return 0;
  }
}
