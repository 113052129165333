import {
  MdBlock,
  MdErrorOutline,
  MdOutlineCancel,
  MdOutlineDone,
  MdOutlinePublic,
  MdOutlinePublicOff,
  MdOutlineQueuePlayNext,
  MdOutlineSend,
  MdOutlineSlideshow,
} from "react-icons/md";
import { defineMessage } from "react-intl";

// Tri
export const TASKS_SORT_VALUE_CREATED_ASC = "created_asc";
export const TASKS_SORT_VALUE_CREATED_DESC = "created_desc";

/**
 * @type {Record<object, {
 * id: string;
 * }>}
 */
export const TASKS_SORTS = {
  [TASKS_SORT_VALUE_CREATED_ASC]: {
    id: TASKS_SORT_VALUE_CREATED_ASC,
  },
  [TASKS_SORT_VALUE_CREATED_DESC]: {
    id: TASKS_SORT_VALUE_CREATED_DESC,
  },
};

export const TASKS_SORT_LIST = Object.values(TASKS_SORTS);

export const TASKS_SORT_MESSAGE = defineMessage({
  defaultMessage:
    "{sort, select, created_asc {Date de création croissante} created_desc {Date de création décroissante} other {{sort}}}",
});

export const TASKS_STATE_VALUE_QUEUED = "queued";
export const TASKS_STATE_VALUE_DISPATCHED = "dispatched";
export const TASKS_STATE_VALUE_STARTED = "started";
export const TASKS_STATE_VALUE_COMPLETED = "completed";
export const TASKS_STATE_VALUE_CANCELED = "canceled";
export const TASKS_STATE_VALUE_FAILED = "failed";

/**
 * @type {Record<import("../types/Task").TaskStateType, {
 * id: import("../types/Task").TaskStateType;
 * label: import("react-intl").MessageDescriptor;
 * icon: import("react-icons").IconType;
 * colors: string[];
 * }>}
 */
export const TASKS_STATES = {
  [TASKS_STATE_VALUE_QUEUED]: {
    id: TASKS_STATE_VALUE_QUEUED,
    label: defineMessage({ defaultMessage: "En file d'attente" }),
    colors: ["purple.600", "purple.300"],
    icon: MdOutlineQueuePlayNext,
  },
  [TASKS_STATE_VALUE_DISPATCHED]: {
    id: TASKS_STATE_VALUE_DISPATCHED,
    label: defineMessage({ defaultMessage: "Envoyée" }),
    colors: ["green.600", "green.300"],
    icon: MdOutlineSend,
  },
  [TASKS_STATE_VALUE_STARTED]: {
    id: TASKS_STATE_VALUE_STARTED,
    label: defineMessage({ defaultMessage: "Démarrée" }),
    colors: ["green.600", "green.300"],
    icon: MdOutlineSlideshow,
  },
  [TASKS_STATE_VALUE_COMPLETED]: {
    id: TASKS_STATE_VALUE_COMPLETED,
    label: defineMessage({ defaultMessage: "Terminée" }),
    colors: ["green.600", "green.300"],
    icon: MdOutlineDone,
  },
  [TASKS_STATE_VALUE_CANCELED]: {
    id: TASKS_STATE_VALUE_CANCELED,
    label: defineMessage({ defaultMessage: "Annulée" }),
    colors: ["orange.600", "orange.300"],
    icon: MdOutlineCancel,
  },
  [TASKS_STATE_VALUE_FAILED]: {
    id: TASKS_STATE_VALUE_FAILED,
    label: defineMessage({ defaultMessage: "Échouée" }),
    colors: ["red.600", "red.300"],
    icon: MdErrorOutline,
  },
};

export const TASKS_STATE_LIST = Object.values(TASKS_STATES);

export const tasksStatesMessage = defineMessage({
  defaultMessage:
    "{state, select, queued {En file d'attente} dispatched {Envoyée} started {Démarrée} completed {Terminée} canceled {Annulée} failed {Échouée} other {state}}",
});

export const TASKS_VISIBILITY_VALUE_PRIVATE = "private";
export const TASKS_VISIBILITY_VALUE_PUBLIC = "public";
export const TASKS_VISIBILITY_VALUE_LIMITED = "limited";

/**
 * @type {Record<import("../types/Task").TaskVisibilityType, {
 * id: import("../types/Task").TaskVisibilityType;
 * label: import("react-intl").MessageDescriptor;
 * icon: import("react-icons").IconType;
 * colors: string[];
 * }>}
 */
export const TASKS_VISIBILITIES = {
  [TASKS_VISIBILITY_VALUE_PRIVATE]: {
    id: TASKS_VISIBILITY_VALUE_PRIVATE,
    label: defineMessage({ defaultMessage: "Privé" }),
    icon: MdOutlinePublicOff,
    colors: ["purple.600", "purple.300"],
  },
  [TASKS_VISIBILITY_VALUE_PUBLIC]: {
    id: TASKS_VISIBILITY_VALUE_PUBLIC,
    label: defineMessage({ defaultMessage: "Publique" }),
    icon: MdOutlinePublic,
    colors: ["green.600", "green.300"],
  },
  [TASKS_VISIBILITY_VALUE_LIMITED]: {
    id: TASKS_VISIBILITY_VALUE_LIMITED,
    label: defineMessage({ defaultMessage: "Limitée" }),
    icon: MdBlock,
    colors: ["orange.600", "orange.300"],
  },
};

export const TASKS_VISIBILITY_LIST = Object.values(TASKS_VISIBILITIES);

export const taskVisibilityMessage = defineMessage({
  defaultMessage:
    "{visibility, select, public {Publique} private {Privée} limited {Limitée} other {visibility}}",
});

/* Tasks type */
export const TASKS_OBJECT_TYPES = {};

export const TASKS_OBJECT_TYPES_LIST = Object.values(TASKS_OBJECT_TYPES);

export const tasksObjectsTypesMessage = defineMessage({
  defaultMessage: "{object_type, select, file {Fichier} other {object_type}}",
});

export const TASKS_TYPE_VALUE_BOOKING_EXPORT = `App\\Jobs\\Booking\\Export`;
export const TASKS_TYPE_VALUE_CUSTOMER_TRAVELER_EXPORT = `App\\Jobs\\Customer\\Traveler\\Export`;
export const TASKS_TYPE_VALUE_HOTEL_EXPORT = `App\\Jobs\\Hotel\\Export`;
export const TASKS_TYPE_VALUE_HOTEL_ROOM_EXPORT = `App\\Jobs\\Hotel\\Room\\Export`;
export const TASKS_TYPE_VALUE_HOTEL_ROOM_REMOTE_URL_EXPORT = `App\\Jobs\\Hotel\\Room\\RemoteUrlExport`;
export const TASKS_TYPE_VALUE_HOTEL_ROOM_AVAILABILITY_REMOTE_EXPORT = `App\\Jobs\\Hotel\\Room\\Availability\\Remote\\Export`;
export const TASKS_TYPE_VALUE_INVOICE_EXPORT = `App\\Jobs\\Invoice\\Export`;
export const TASKS_TYPE_VALUE_NEWSGROUP_SUBSCRIBER_EXPORT = `App\\Jobs\\Newsgroup\\Subscriber\\Export`;
export const TASKS_TYPE_VALUE_PAYMENT_EXPORT = `App\\Jobs\\Payment\\Export`;
export const TASKS_TYPE_VALUE_PAYMENT_SEPA_EXPORT = `App\\Jobs\\Payment\\Sepa\\Export`;
export const TASKS_TYPE_VALUE_SUBSCRIPTION_SUBSCRIPTION_CUSTOMER_EXPORT = `App\\Jobs\\Subscription\\Subscription\\Customer\\Export`;
export const TASKS_TYPE_VALUE_USER_ADMIN_EXPORT = `App\\Jobs\\User\\Admin\\Export`;
export const TASKS_TYPE_VALUE_USER_CUSTOMER_EXPORT = `App\\Jobs\\User\\Customer\\Export`;
export const TASKS_TYPE_VALUE_FILE_DELETE = `App\\Jobs\\File\\Delete`;

/**
 * @type {Record<object, {
 * id: string;
 * }>}
 */
export const TASKS_TYPES = {
  [TASKS_TYPE_VALUE_BOOKING_EXPORT]: {
    id: TASKS_TYPE_VALUE_BOOKING_EXPORT,
  },
  [TASKS_TYPE_VALUE_CUSTOMER_TRAVELER_EXPORT]: {
    id: TASKS_TYPE_VALUE_CUSTOMER_TRAVELER_EXPORT,
  },
  [TASKS_TYPE_VALUE_HOTEL_EXPORT]: {
    id: TASKS_TYPE_VALUE_HOTEL_EXPORT,
  },
  [TASKS_TYPE_VALUE_HOTEL_ROOM_EXPORT]: {
    id: TASKS_TYPE_VALUE_HOTEL_ROOM_EXPORT,
  },
  [TASKS_TYPE_VALUE_HOTEL_ROOM_REMOTE_URL_EXPORT]: {
    id: TASKS_TYPE_VALUE_HOTEL_ROOM_REMOTE_URL_EXPORT,
  },
  [TASKS_TYPE_VALUE_HOTEL_ROOM_AVAILABILITY_REMOTE_EXPORT]: {
    id: TASKS_TYPE_VALUE_HOTEL_ROOM_AVAILABILITY_REMOTE_EXPORT,
  },
  [TASKS_TYPE_VALUE_INVOICE_EXPORT]: {
    id: TASKS_TYPE_VALUE_INVOICE_EXPORT,
  },
  [TASKS_TYPE_VALUE_NEWSGROUP_SUBSCRIBER_EXPORT]: {
    id: TASKS_TYPE_VALUE_NEWSGROUP_SUBSCRIBER_EXPORT,
  },
  [TASKS_TYPE_VALUE_PAYMENT_EXPORT]: {
    id: TASKS_TYPE_VALUE_PAYMENT_EXPORT,
  },
  [TASKS_TYPE_VALUE_PAYMENT_SEPA_EXPORT]: {
    id: TASKS_TYPE_VALUE_PAYMENT_SEPA_EXPORT,
  },
  [TASKS_TYPE_VALUE_SUBSCRIPTION_SUBSCRIPTION_CUSTOMER_EXPORT]: {
    id: TASKS_TYPE_VALUE_SUBSCRIPTION_SUBSCRIPTION_CUSTOMER_EXPORT,
  },
  [TASKS_TYPE_VALUE_USER_ADMIN_EXPORT]: {
    id: TASKS_TYPE_VALUE_USER_ADMIN_EXPORT,
  },
  [TASKS_TYPE_VALUE_USER_CUSTOMER_EXPORT]: {
    id: TASKS_TYPE_VALUE_USER_CUSTOMER_EXPORT,
  },
  [TASKS_TYPE_VALUE_FILE_DELETE]: {
    id: TASKS_TYPE_VALUE_FILE_DELETE,
  },
};

export const tasksTypesMessage = defineMessage({
  defaultMessage: `{type, select, 
  AppJobsBookingExport {Exportation de réservations}
  AppJobsCustomerTravelerExport {Exportation de voyageurs}
  AppJobsHotelExport {Exportation de structures}
  AppJobsHotelRoomExport {Exportation d'hébergements}
  AppJobsHotelRoomRemoteUrlExport {Exportation de iCal}
  AppJobsHotelRoomAvailabilityRemoteExport {Exportation de liens iCal}
  AppJobsInvoiceExport {Exportation de factures}
  AppJobsNewsgroupSubscriberExport {Exportation d'abonnés newsletter}
  AppJobsPaymentExport {Exportation de paiements}
  AppJobsPaymentSepaExport {Exportation de paiements SEPA}
  AppJobsSubscriptionSubscriptionCustomerExport {Exportation d'abonnements}
  AppJobsUserAdminExport {Exportation d'administrateurs}
  AppJobsUserCustomerExport {Exportation d'interlocuteurs}
  AppJobsFileDelete {Suppression de fichiers}
  other {type}}`,
});

export const TASKS_TYPES_LIST = Object.values(TASKS_TYPES);
