// deps
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import useSWR from "swr";

// hooks
import useApiFetcher from "../../hooks/useApiFetcher";

// contexts
import { SearchContext } from ".";
import initialRouterSyncUrl from "../defautlPushToUrl";
import { usePreferences } from "../Preferences";

// libraries
import encodeQuery from "@splitfire-agency/raiden-library/dist/libraries/utils/encodeQuery";
import parseQuery from "@splitfire-agency/raiden-library/dist/libraries/utils/parseQuery";

// constants
import { SESSION_STORAGE_BASE_SEARCH_SAVED_FIELDS } from "../../constants/storage";
import { PER_PAGES } from "../../constants/api";
import { useRouter } from "next/router";

/**
 * Permet d'exclure des filtres à la réinitialisation du formulaire.
 * @param {Array<string>} excludeFiltersToBeReset
 * @param {Record<any, any>} submittedFields
 * @returns {Record<any, any>}
 */
function excludedFilterToBeReset(excludeFiltersToBeReset, submittedFields) {
  let excludedFilters = {};

  if (excludeFiltersToBeReset.length > 0) {
    for (const filter of excludeFiltersToBeReset) {
      if (submittedFields[filter]) {
        excludedFilters = {
          ...excludedFilters,
          [filter]: submittedFields[filter],
        };
      }
    }
    return excludedFilters;
  }
  return excludedFilters;
}

/**
 * Initiliase les valeurs par défaut du formulaire.
 * @param {object} param0
 * @param {boolean} param0.paginated
 * @param {number} param0.paginationPerPage
 * @param {object} [param0.defaultValues]
 * @param {object} [param0.queryValues]
 * @returns {object}
 */
const getInitialValues = function ({
  paginated,
  paginationPerPage,
  defaultValues = {},
  queryValues = {},
}) {
  const initialDefaultValues = { ...defaultValues, ...queryValues };
  if (paginated && null == initialDefaultValues.per_page) {
    initialDefaultValues.per_page = paginationPerPage;
  }
  return initialDefaultValues;
};

const initialPaginationUrl = function ({ fields }) {
  return `${encodeQuery(fields, {
    appendPrefix: true,
  })}`;
};

/**
 * @typedef {object} Props
 * @property {string} searchId
 * @property {(params: object) => string | null} endpointUrl
 * @property {false | ((params: { fields: object }) => string)} [paginationUrl]
 * @property {boolean} [paginated]
 * @property {Array<number>} [displayedPerPages]
 * @property {false | ((params: { fields: object }) => void)} [routerSyncUrl]
 * @property {boolean} [useSessionStorageHydration]
 * @property {boolean} [preventSubmitIfNotDirty]
 * @property {Array<string>} [excludeFiltersToBeReset]
 * @property {number} [refreshInterval]
 * @property {object} [defaultValues]
 */
/**
 * @param {import("react").PropsWithChildren<Props>} props
 * @returns {import("react").FunctionComponentElement<Props>}
 */
function SearchProvider({
  searchId,
  endpointUrl,
  paginated = true,
  displayedPerPages = PER_PAGES,
  preventSubmitIfNotDirty = false,
  paginationUrl = initialPaginationUrl,
  routerSyncUrl = initialRouterSyncUrl,
  useSessionStorageHydration = false,
  excludeFiltersToBeReset = ["only_trash", "_tab"],
  refreshInterval = 0,
  defaultValues = {},
  children,
}) {
  const { paginationPerPage } = usePreferences();

  const router = useRouter();

  /**
   /**
   * Permet d'exclure des valeurs de l'URL du router.
   * on utilise cette méthode en interne à la place de router.query pour éviter de récupérer des valeurs non souhaitées
   * par exemple sur une url du type customers/170033/view/services?user_id=170033&sort=created_desc&per_page=100
   * si on utiliser router.query on récupère user_id, sort, per_page, mais également id (170033 de  customers/170033/view/services) car c'est une valeur dynamique injectée dans le router
   * en utilisant cette méthode on peut exclure id de la récupération des valeurs
   * @returns {object}
   */
  function getRouterQuery() {
    return parseQuery(router.asPath.split("?")[1] ?? "");
  }

  /**
   * Valeurs par défaut du formulaire.
   * on initialise les paramètres avec la logique suivante
   *  1) on récupère les valeurs de la props defaultValues
   *  2) si on a un routerSyncUrl, on récupère les valeurs du router que l'on surcharge à l'objet retourné par le 1)
   */

  const initialValues = getInitialValues({
    paginationPerPage,
    queryValues:
      "function" === typeof routerSyncUrl && getRouterQuery()
        ? getRouterQuery()
        : {},
    defaultValues: defaultValues ?? {},
    paginated,
  });

  const [mounted, setMounted] = useState(false);

  const form = useForm({
    defaultValues: initialValues,
  });
  const [submittedFields, setSubmitted] = useState(initialValues);

  const { watch, setValue } = form;

  /**
   * Réinitialise la pagination lors d'un changement de filtre.
   */
  useEffect(
    function () {
      if (mounted && paginated) {
        const subscription = watch(function (value, { name }) {
          if (name !== "page") {
            setValue("page", "1", { shouldDirty: true });
          }
        });
        return () => subscription.unsubscribe();
      }
    },
    [paginated, watch, setValue, mounted],
  );

  const [swrOptions, setSwrOptions] = useState({
    refreshInterval: refreshInterval,
  });

  /**
   * Synchronise l'URL du router avec les valeurs du formulaire.
   * @param {object} options
   * @param {object} options.fields
   * @returns {void}
   */
  const updateRouterUrl = useCallback(
    function updateRouterUrl({ fields }) {
      if ("function" === typeof routerSyncUrl) {
        router.replace(
          `${router.asPath.split("?")[0]}${encodeQuery(
            routerSyncUrl({ fields }),
            {
              appendPrefix: true,
            },
          )}${window.location.hash}`,
          undefined,
          { shallow: true },
        );
      }
    },
    [router, routerSyncUrl],
  );

  /**
   * Gère la soumission du formulaire.
   */
  const handleSubmit = form.handleSubmit(function (fields) {
    // ce teste pour soumettre le formulaire uniquement si les valeurs n'ont changé
    // on utilisera la fonction mutate de swr pour forcer le rafraichissement
    if (endpointUrl({ fields: submittedFields }) === endpointUrl({ fields })) {
      response.mutate();
    } else {
      // récupère la liste des valeurs soumises (après un click sur le bouton ou au mount du search) du formulaire (visible) pour les soumettre
      setSubmitted(fields);
      updateRouterUrl({ fields });
      if (useSessionStorageHydration) {
        if (searchId) {
          window.sessionStorage.setItem(
            `${SESSION_STORAGE_BASE_SEARCH_SAVED_FIELDS}-${searchId}`,
            JSON.stringify({ ...fields, page: "1" }),
          );
        }
      }
    }
  });

  /**
   * Réinitialise les valeurs par défaut du formulaire.
   * @returns {void}
   */
  function resetDefaultValues() {
    form.reset(
      getInitialValues({
        paginationPerPage,
        defaultValues: {
          ...defaultValues,
          ...excludedFilterToBeReset(excludeFiltersToBeReset, submittedFields),
        },
        paginated,
      }),
    );
  }

  /**
   * Gestion de l'hydratation du formulaire via le session storage.
   * si on trouve des champs on soumet le formulaire
   */
  useEffect(function () {
    if (!mounted) {
      const diffFields = {};
      if (useSessionStorageHydration) {
        // Si l’hydratation via le stockage session est activé et qu’il s’agit du premier rendu
        const key = `${SESSION_STORAGE_BASE_SEARCH_SAVED_FIELDS}-${searchId}`;
        const rawFields = window.sessionStorage.getItem(key);
        window.sessionStorage.removeItem(key);
        if (rawFields) {
          Object.assign(diffFields, JSON.parse(rawFields));
        }
      }

      if (Object.keys(diffFields).length > 0) {
        // on récupère les valeurs du router par défaut
        const routerValues = getInitialValues({
          paginationPerPage,
          queryValues:
            "function" === typeof routerSyncUrl && getRouterQuery()
              ? getRouterQuery()
              : {},
          paginated: false,
        });
        // si dans l'url on dispose d'élèment, on utilise pas les valeurs du session storage, pour éviter les incohérences de fields
        if (Object.keys(routerValues).length > 0) {
          return;
        }
        for (const [fieldName, fieldValue] of Object.entries(diffFields)) {
          setValue(fieldName, fieldValue);
        }
        handleSubmit();
      }
    }
  });

  const apiFetcher = useApiFetcher();

  const response = useSWR(
    mounted && endpointUrl ? endpointUrl({ fields: submittedFields }) : null,
    apiFetcher,
    swrOptions,
  );

  const [tagsRefreshId, setTagsRefreshId] = useState({});

  /**
   * Tells to update tags
   */
  const updateTags = useCallback(function () {
    setTagsRefreshId({});
  }, []);

  const appliedFilters = useMemo(() => {
    // for each key in submitted fields
    return Object.keys(submittedFields).reduce((acc, key) => {
      // if the submitted value is different from the default value
      if (Array.isArray(submittedFields[key])) {
        const isDifferent = submittedFields[key].some(
          (value) => !defaultValues[key]?.includes(value),
        );
        if (isDifferent) {
          acc = [...acc, key];
        }
      } else if (submittedFields[key] !== defaultValues[key]) {
        acc = [...acc, key];
      }
      return acc;
    }, /** @type {string[]} */ ([]));
  }, [defaultValues, submittedFields]);

  /** @type {import("./Context").SearchContextValue} */
  const value = {
    searchId,
    tagsRefreshId,
    setSwrOptions,
    swrOptions,
    form,
    submittedFields,
    displayedPerPages,
    paginationUrl,
    preventSubmitIfNotDirty,
    response,
    onSubmit: handleSubmit,
    updateTags,
    reset: resetDefaultValues,
    defaultValues,
    appliedFilters,
    routerSyncUrl,
  };

  useEffect(function () {
    setMounted(true);
  }, []);

  useEffect(
    function () {
      if (!mounted) {
        updateRouterUrl({ fields: submittedFields });
      }
    },
    [updateRouterUrl, mounted, submittedFields],
  );

  return (
    <SearchContext.Provider value={value}>
      <FormProvider {...form}>{children}</FormProvider>
    </SearchContext.Provider>
  );
}

export default SearchProvider;
