// deps
import { useIntl } from "react-intl";
import {
  Input,
  InputGroup,
  InputRightElement,
  WrapItem,
  Icon,
} from "@chakra-ui/react";
import { IoSearch } from "react-icons/io5";

// components
import EnvironmentSelect from "../ReactHookForm/FormControlEnvironmentSelect";
import FormControlRHF from "@raiden/library-ui/components/ReactHookForm/FormControlRHF";
import SubscriptionSelect from "../ReactHookForm/FormControlSubscriptionsSelect";
import { CustomersAutocomplete } from "@raiden/library-ui/components/Form/CustomersAutocomplete";
import { CustomersAutocompleteMultiple } from "@raiden/library-ui/components/Form/CustomersAutocompleteMultiple";
import { RoomsAutocomplete } from "@raiden/library-ui/components/Form/RoomsAutocomplete";
import { InsurancesAutocomplete } from "@raiden/library-ui/components/Form/InsurancesAutocomplete";
import { InsurancesAutocompleteMultiple } from "@raiden/library-ui/components/Form/InsurancesAutocompleteMultiple";

import PartnerSelect from "../ReactHookForm/FormControlPartnerSelect";
import { RoomsAutocompleteMultiple } from "@raiden/library-ui/components/Form//RoomsAutocompleteMultiple";
import { UsersAdminAutocomplete } from "@raiden/library-ui/components/Form/UsersAdminAutocomplete";
import { RegionsAutocompleteMultiple } from "@raiden/library-ui/components/Form/RegionsAutocompleteMultiple";

// hoc
import injectSearchId from "./injectSearchIdHoc";
import { useWatch } from "react-hook-form";

/**
 * @typedef {object} Props
 * @property {boolean} [withTerm]
 * @property {string} [termPlaceholder]
 * @property {string} [environmentPlaceholder]
 * @property {boolean} [withEnvironments]
 * @property {boolean} [withInsurances]
 * @property {boolean} [withPartners]
 * @property {boolean} [withSubcriptions]
 * @property {boolean} [withFrom]
 * @property {boolean} [withTo]
 * @property {boolean} [shouldBypassRenderEnvironments]
 * @property {boolean} [withCustomers]
 * @property {boolean} [withAdmins]
 * @property {boolean} [withRooms]
 * @property {boolean} [withMultipleRooms]
 * @property {boolean} [withMultipleEnvironments]
 * @property {boolean} [withMultipleInsurances]
 * @property {boolean} [withMultiplePartners]
 * @property {boolean} [watchEnvironments]
 * @property {boolean} [watchCustomers]
 * @property {boolean} [withMultipleCustomers]
 * @property {boolean} [withRegions]
 * @property {boolean} [withMultipleRegions]
 * @property {string} [nameCustomer]
 * @property {string} [nameAdmin]
 * @property {string} [nameTerm]
 * @property {string} [nameEnvironment]
 * @property {string} [nameFrom]
 * @property {string} [nameTo]
 * @property {string} [nameInsurance]
 * @property {string} [namePartner]
 * @property {string} [nameSubscription]
 * @property {string} [nameRooms]
 * @property {string} [nameRegions]
 * @property {string} [authorizations]
 * @property {string} [searchId]
 * @property {string} [adminPlaceholder]
 * @property {() => void} [onHydrationComplete]
 */

/**
 * @param {Props} props
 * @returns {import("react").FunctionComponentElement<Props>}
 */
function SearchCommonFilterListRHF(props) {
  const {
    withTerm = false,
    termPlaceholder,
    environmentPlaceholder,
    withEnvironments = false,
    watchEnvironments = false,
    shouldBypassRenderEnvironments = false,
    withMultipleEnvironments = true,
    withCustomers = false,
    watchCustomers = false,
    withRooms = false,
    withMultipleRooms = false,
    withInsurances = false,
    withMultipleInsurances = false,
    withMultiplePartners = false,
    withSubcriptions = false,
    withFrom = false,
    withTo = false,
    withPartners = false,
    withMultipleCustomers = false,
    withAdmins = false,
    withRegions = false,
    withMultipleRegions = false,
    authorizations,
    nameCustomer = "customer_id",
    nameAdmin = "admin_id",
    nameTerm = "term",
    nameEnvironment = "environment_id",
    nameFrom = "min_date",
    nameTo = "max_date",
    nameInsurance = "insurance_id",
    nameSubscription = "subscription_id",
    nameRooms = "room_id",
    namePartner = "partner_id",
    nameRegions = "region_id",
    searchId,
    adminPlaceholder,
    onHydrationComplete,
  } = props;

  const intl = useIntl();

  const watchEnvironmentValue = useWatch({
    name: nameEnvironment,
  });

  const watchCustomerValue = useWatch({
    name: nameCustomer,
  });

  return (
    <>
      {withTerm && (
        <WrapItem>
          <FormControlRHF
            name={nameTerm}
            label={intl.formatMessage({ defaultMessage: "Rechercher" })}
            labelProps={{ "data-search-id": searchId }}>
            {(props) => (
              <InputGroup>
                <InputRightElement
                  zIndex={"auto"}
                  pointerEvents="none"
                  children={<Icon as={IoSearch} />}
                />
                <Input
                  {...props}
                  type="search"
                  placeholder={
                    termPlaceholder ??
                    intl.formatMessage({ defaultMessage: "Rechercher..." })
                  }
                />
              </InputGroup>
            )}
          </FormControlRHF>
        </WrapItem>
      )}

      <EnvironmentSelect
        environmentPlaceholder={
          environmentPlaceholder ??
          intl.formatMessage({
            defaultMessage: "Sélectionner...",
          })
        }
        authorizations={authorizations}
        withMultipleEnvironments={withMultipleEnvironments}
        labelProps={{ "data-search-id": searchId }}
        label={intl.formatMessage({
          defaultMessage: "Filtrer par environnement",
        })}
        name={nameEnvironment}
        shouldBypassRenderEnvironments={shouldBypassRenderEnvironments}
        withEnvironments={withEnvironments}
      />

      {withCustomers && !withMultipleCustomers && (
        <WrapItem>
          <FormControlRHF
            labelProps={{ "data-search-id": searchId }}
            name={nameCustomer}
            label={intl.formatMessage({
              defaultMessage: "Filtrer par propriétaire",
            })}
            renderWithFormControl={(fields) => (
              <CustomersAutocomplete
                {...fields}
                maxWidth="250px"
                onHydrationComplete={onHydrationComplete}
                environmentId={
                  watchEnvironments ? watchEnvironmentValue : undefined
                }
              />
            )}
          />
        </WrapItem>
      )}
      {withAdmins && (
        <WrapItem>
          <FormControlRHF
            labelProps={{ "data-search-id": searchId }}
            name={nameAdmin}
            label={
              adminPlaceholder ??
              intl.formatMessage({
                defaultMessage: "Filtrer par administrateur",
              })
            }
            renderWithFormControl={(fields) => (
              <UsersAdminAutocomplete
                {...fields}
                maxWidth={"250px"}
                onHydrationComplete={onHydrationComplete}
              />
            )}
          />
        </WrapItem>
      )}
      {withCustomers && withMultipleCustomers && (
        <WrapItem>
          <FormControlRHF
            labelProps={{ "data-search-id": searchId }}
            name={nameCustomer}
            label={intl.formatMessage({
              defaultMessage: "Filtrer par propriétaire",
            })}
            renderWithFormControl={(fields) => (
              <CustomersAutocompleteMultiple
                {...fields}
                maxWidth="250px"
                onHydrationComplete={onHydrationComplete}
                environmentId={
                  watchEnvironments ? watchEnvironmentValue : undefined
                }
              />
            )}
          />
        </WrapItem>
      )}
      {withRooms && !withMultipleRooms && (
        <WrapItem>
          <FormControlRHF
            labelProps={{ "data-search-id": searchId }}
            name={nameRooms}
            label={intl.formatMessage({
              defaultMessage: "Filtrer par hébergement",
            })}
            renderWithFormControl={(fields) => (
              <RoomsAutocomplete
                maxWidth={"250px"}
                {...fields}
                onHydrationComplete={onHydrationComplete}
                customerId={watchCustomers ? watchCustomerValue : undefined}
              />
            )}
          />
        </WrapItem>
      )}

      {withRooms && withMultipleRooms && (
        <WrapItem>
          <FormControlRHF
            labelProps={{ "data-search-id": searchId }}
            name={nameRooms}
            label={intl.formatMessage({
              defaultMessage: "Filtrer par hébergement",
            })}
            renderWithFormControl={(fields) => (
              <RoomsAutocompleteMultiple
                maxWidth={"250px"}
                {...fields}
                onHydrationComplete={onHydrationComplete}
                customerId={watchCustomers ? watchCustomerValue : undefined}
              />
            )}
          />
        </WrapItem>
      )}

      {withFrom && (
        <WrapItem>
          <FormControlRHF
            name={nameFrom}
            type={"datetime-picker"}
            label={intl.formatMessage({
              defaultMessage: "Du",
            })}
            labelProps={{ "data-search-id": searchId }}
            placeholder={intl.formatMessage({
              defaultMessage: "Sélectionner...",
            })}
          />
        </WrapItem>
      )}

      {withTo && (
        <WrapItem>
          <FormControlRHF
            name={nameTo}
            type={"datetime-picker"}
            label={intl.formatMessage({
              defaultMessage: "Au",
            })}
            labelProps={{ "data-search-id": searchId }}
            placeholder={intl.formatMessage({
              defaultMessage: "Sélectionner...",
            })}
          />
        </WrapItem>
      )}

      {withInsurances && !withMultipleInsurances && (
        <WrapItem>
          <FormControlRHF
            labelProps={{ "data-search-id": searchId }}
            name={nameInsurance}
            label={intl.formatMessage({
              defaultMessage: "Filtrer par assurance",
            })}
            renderWithFormControl={(fields) => (
              <InsurancesAutocomplete
                maxWidth={"250px"}
                {...fields}
                onHydrationComplete={onHydrationComplete}
              />
            )}
          />
        </WrapItem>
      )}

      {withInsurances && withMultipleInsurances && (
        <WrapItem>
          <FormControlRHF
            labelProps={{ "data-search-id": searchId }}
            name={nameInsurance}
            label={intl.formatMessage({
              defaultMessage: "Filtrer par assurance",
            })}
            renderWithFormControl={(fields) => (
              <InsurancesAutocompleteMultiple
                maxWidth={"250px"}
                {...fields}
                onHydrationComplete={onHydrationComplete}
              />
            )}
          />
        </WrapItem>
      )}

      {withSubcriptions && (
        <SubscriptionSelect
          placeholder={intl.formatMessage({
            defaultMessage: "Sélectionner...",
          })}
          labelProps={{ "data-search-id": searchId }}
          label={intl.formatMessage({
            defaultMessage: "Filtrer par formule",
          })}
          name={nameSubscription}
          withSubscriptions={withSubcriptions}
        />
      )}
      {withPartners && (
        <PartnerSelect
          placeholder={intl.formatMessage({
            defaultMessage: "Sélectionner...",
          })}
          labelProps={{ "data-search-id": searchId }}
          label={intl.formatMessage({
            defaultMessage: "Filtrer par partenaire",
          })}
          name={namePartner}
          withPartners={withPartners}
          isMultiple={withMultiplePartners}
        />
      )}
      {withRegions && withMultipleRegions && (
        <WrapItem>
          <FormControlRHF
            labelProps={{ "data-search-id": searchId }}
            label={intl.formatMessage({
              defaultMessage: "Filtrer par région",
            })}
            name={nameRegions}
            renderWithFormControl={(field) => (
              <RegionsAutocompleteMultiple
                {...field}
                maxWidth={"250px"}
                onHydrationComplete={onHydrationComplete}
              />
            )}
          />
        </WrapItem>
      )}
    </>
  );
}

export default injectSearchId(SearchCommonFilterListRHF);
