// deps
import { useIntl } from "react-intl";

// components
import Splash from "@raiden/library-ui/components/Splash";
import { Box, VStack } from "@chakra-ui/react";

/**
 * @typedef {object} Props
 * @property {React.ReactNode} [addon]
 */

/**
 * @param {Props} props
 */
export default function MaintenanceSplash(props) {
  const { addon } = props;
  const intl = useIntl();

  return (
    <Splash
      title={intl.formatMessage({
        defaultMessage: "Maintenance en cours",
      })}
      description={
        <VStack spacing="1rem">
          <Box>
            {intl.formatMessage({
              defaultMessage:
                "Nous sommes désolés, mais une maintenance est en cours sur votre logiciel. Merci d’attendre quelques instants.",
            })}
          </Box>
          {addon}
        </VStack>
      }
      image="error"
    />
  );
}
