import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { ShowData, useShow } from "@raiden/library-ui/contexts/Show";
import { useIntl } from "react-intl";
import { TasksActionsCancel } from "../Actions/Cancel";
import { TasksActionsRetry } from "../Actions/Retry";
import { InfoTable } from "./InfoTable";

/**
 * @typedef Props
 * @property {boolean} isOpen
 * @property {() => void} onClose
 * @property {() => void} mutateSearch
 */
/**
 * @param {import("react").PropsWithChildren<Props>} props
 * @returns {import("react").FunctionComponentElement<Props>}
 */
export function Data({ isOpen, onClose, mutateSearch }) {
  const intl = useIntl();

  const { data: dataResponse, mutate } = useShow();

  function mutateAll() {
    mutateSearch();
    mutate();
  }

  const resource = dataResponse?.data ?? null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      blockScrollOnMount={false}
      size="2xl">
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton />

        <ModalHeader>
          {intl.formatMessage(
            {
              defaultMessage: "Détail de la tâche {id}",
            },
            { resourceType: resource?.type, id: resource?.id },
          )}
        </ModalHeader>

        <ShowData
          data={function () {
            return (
              <>
                <ModalBody>
                  <InfoTable resource={resource} />
                </ModalBody>

                <ModalFooter>
                  <ButtonGroup size="sm">
                    <TasksActionsCancel
                      resource={resource}
                      onSuccess={mutateAll}>
                      {function ({ disclosure }) {
                        return (
                          <Button onClick={disclosure.onOpen}>
                            {intl.formatMessage({
                              defaultMessage: "Annuler la tâche",
                            })}
                          </Button>
                        );
                      }}
                    </TasksActionsCancel>

                    <TasksActionsRetry
                      resource={resource}
                      onSuccess={mutateAll}>
                      {function ({ disclosure }) {
                        return (
                          <Button onClick={disclosure.onOpen}>
                            {intl.formatMessage({
                              defaultMessage: "Relancer la tâche",
                            })}
                          </Button>
                        );
                      }}
                    </TasksActionsRetry>

                    <Button variant="outline" onClick={onClose}>
                      {intl.formatMessage({
                        defaultMessage: "Fermer",
                      })}
                    </Button>
                  </ButtonGroup>
                </ModalFooter>
              </>
            );
          }}
        />
      </ModalContent>
    </Modal>
  );
}
