// deps
import { mode } from "@chakra-ui/theme-tools";

export default {
  global: (props) => ({
    "html, body": {
      height: "100%",
      bgColor: "gray.700",
    },
    body: {
      fontFamily: "Helvetica, sans-serif",
    },
    "#__next": {
      height: "100%",
    },
    "*::placeholder": {
      color: mode("gray.400", "gray.500")(props),
    },
    ".renderer h1": {
      color: "#002538",
      fontFamily: "'Outfit', sans-serif",
      fontSize: { base: "1.875rem", lg: "2.5rem" },
      fontWeight: "400",
      lineHeight: { base: "2.3625rem", lg: "3.15rem" },
    },
    ".renderer h2": {
      color: "#002538",
      fontFamily: "'Outfit', sans-serif",
      fontSize: { base: "1.625rem", lg: "2rem" },
      fontWeight: "400",
      lineHeight: { base: "2.0475rem", lg: "2.52rem" },
      letterSpacing: "0.04rem",
    },
    ".renderer h3": {
      color: "#002538",
      fontFamily: "'Outfit', sans-serif",
      fontSize: "1.75rem",
      fontWeight: "300",
      lineHeight: "2.205rem",
      letterSpacing: "0.02em",
    },
    ".renderer h4": {
      color: "#002538",
      fontFamily: "'Outfit', sans-serif",
      fontSize: "1.25rem",
      fontWeight: "400",
      lineHeight: "1.575rem",
      letterSpacing: "0.02em",
    },
    ".renderer h5": {
      color: "#002538",
      fontFamily: "'Outfit', sans-serif",
      fontSize: "1.125rem",
      fontWeight: "400",
    },
    ".renderer h6": {
      color: "#002538",
      fontFamily: "'Outfit', sans-serif",
      fontSize: "1rem",
      fontWeight: "400",
    },
    ".renderer p, .renderer .paragraph, .renderer ul": {
      color: "#002538",
      fontFamily: "'Outfit', sans-serif",
      fontSize: "1rem",
      fontWeight: "400",
      mt: "0.875rem",
      ":first-child": {
        mt: 0,
      },
    },
    ".renderer ul": {
      marginLeft: "1.5rem",
    },
    ".renderer ol": {
      marginLeft: "1.5rem",
    },
    ".renderer p a": {
      color: "#008CD2",
      textDecoration: "none",
      ":hover": {
        textDecoration: "underline",
      },
    },
    ".renderer .rdw-link-decorator-wrapper a": {
      color: "#008CD2",
      textDecoration: "none",
      ":hover": {
        textDecoration: "underline",
      },
    },
  }),
};
