import PropTypes from "prop-types";
import { createContext, memo, useContext } from "react";
import { useApi } from "../../hooks/useApi";

/**
 * @typedef {object} ShowContextValue
 * @property {any} data
 * @property {any} error
 * @property {boolean} loading
 * @property {boolean} isValidating
 * @property {import("swr").KeyedMutator<import("../../types/Api/ApiResponse").ApiResponse<any>>} mutate
 */
export const ShowContext = createContext(
  /** @type {ShowContextValue} */ ({
    data: undefined,
    error: undefined,
    loading: false,
    isValidating: false,
    mutate: () => {},
  }),
);

const { Provider } = ShowContext;

/**
 * @param {{
 * endpointUrl: string,
 * postProcess?: (data: any) => any,
 * children: import("react").ReactNode;
 * }} props
 */
function _ShowProvider({ endpointUrl, postProcess, children }) {
  const {
    swrResponse: { data, isLoading, isValidating, error, mutate },
  } = useApi(endpointUrl);

  const value = {
    data: data ? (postProcess ? postProcess(data) : data) : null,
    error,
    loading: isLoading,
    isValidating,
    mutate,
  };

  return (
    <Provider value={value}>
      {"function" === typeof children ? children(value) : children}
    </Provider>
  );
}

_ShowProvider.displayName = "ShowProvider";

_ShowProvider.propTypes = {
  children: PropTypes.node,
  endpointUrl: PropTypes.string,
};

export const ShowProvider = memo(_ShowProvider);

/**
 * @template T
 * @typedef {object} UseShow
 * @property {import("../../types/Api/ApiResponse").ApiResponse<T>} data
 * @property {any} error
 * @property {boolean} loading
 * @property {boolean} isValidating
 * @property {import("swr").KeyedMutator<import("../../types/Api/ApiResponse").ApiResponse<T>>} mutate
 */
/**
 * @returns {UseShow<any>}
 */
export function useShow() {
  const show = useContext(ShowContext);

  if (null === show) {
    throw new TypeError(
      "Cannot use Show hook without using adding a <ShowProvider> around.",
    );
  }

  return show;
}

export { default as ShowData } from "./Data";
