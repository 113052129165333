// deps
import PropTypes from "prop-types";
import { WrapItem } from "@chakra-ui/react";

// components
import PartnerSelect from "../../Partners/Select";
import FormControlRHF from "@raiden/library-ui/components/ReactHookForm/FormControlRHF";

// hooks
import useCanPerform from "@raiden/library-ui/hooks/useCanPerform";

// constants
import { AUTHORIZATIONS_AUTHORIZATION_VALUE_PARTNERS_VIEW_ANY } from "@raiden/library-ui/constants/authorizations";

/**
 * @typedef {object} Props
 * @property {string} [placeholder]
 * @property {import("@chakra-ui/react").FormLabelProps & {[key: string]: any}} [labelProps]
 * @property {string} [label]
 * @property {string} [name]
 * @property {boolean} [withPartners]
 * @property {boolean} [isMultiple]
 */
/**
 * @param {Props} props
 * @returns {import("react").FunctionComponentElement<Props> | null}
 */
export default function PartnerSelectRHF(props) {
  const {
    withPartners = true,
    isMultiple = false,
    placeholder,
    labelProps,
    label,
    name = "partner_id",
  } = props;

  const canPerform = useCanPerform();

  const can = canPerform({
    authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_PARTNERS_VIEW_ANY,
  });

  if (can && withPartners) {
    return (
      <WrapItem>
        <FormControlRHF
          labelProps={labelProps}
          name={name}
          label={label}
          placeholder={placeholder}
          renderWithFormControl={(field) => (
            <PartnerSelect
              {...field}
              placeholder={placeholder}
              isMultiple={isMultiple}
            />
          )}
        />
      </WrapItem>
    );
  }

  return null;
}

PartnerSelectRHF.propTypes = {
  placeholder: PropTypes.string,
  labelProps: PropTypes.any,
  label: PropTypes.string,
  name: PropTypes.string,
  isMultiple: PropTypes.bool,
};
