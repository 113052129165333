import { Box, useBreakpointValue, VStack } from "@chakra-ui/react";
import ContentBox from "@raiden/library-ui/components/ContentBox";
import Splash from "@raiden/library-ui/components/Splash";
import { SearchData } from "@raiden/library-ui/contexts/Search";
import Table from "@splitfire-agency/raiden-library/dist/components/Table";
import { useIntl } from "react-intl";
import PagePanel from "../../../components/PagePanel";
import { TaskSearchItem } from "./Item";

/**
 * @typedef Props
 * @property {import("@raiden/library-ui/types/Task").Task[]} taskList
 * @property {() => void} mutate
 */
/**
 * @param {Props} props
 * @returns {import("react").FunctionComponentElement<Props>}
 */
export function TaskDrawerData({ taskList, mutate }) {
  const intl = useIntl();

  const responsive = useBreakpointValue({
    base: true,
    xl: false,
  });

  return (
    <SearchData
      renderData={() => (
        <PagePanel.Wrapper as={VStack}>
          <ContentBox w="full" mt="1rem">
            <Table variant="striped2" layout={responsive ? "column" : "row"}>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>
                    {intl.formatMessage({
                      defaultMessage: "Nom",
                    })}
                  </Table.Th>

                  <Table.Th>
                    {intl.formatMessage({
                      defaultMessage: "État",
                    })}
                  </Table.Th>

                  <Table.Th>
                    {intl.formatMessage({
                      defaultMessage: "Progression",
                    })}
                  </Table.Th>

                  <Table.Th>
                    <Box>
                      {intl.formatMessage({
                        defaultMessage: "Date de création",
                      })}
                    </Box>

                    <Box>
                      {intl.formatMessage({
                        defaultMessage: "Date de début",
                      })}
                    </Box>
                  </Table.Th>

                  <Table.Th>
                    {intl.formatMessage({
                      defaultMessage: "Actions",
                    })}
                  </Table.Th>
                </Table.Tr>
              </Table.Thead>

              <Table.Tbody>
                {taskList.map(function (resource) {
                  return (
                    <TaskSearchItem
                      key={resource.id}
                      mutate={mutate}
                      resource={resource}
                    />
                  );
                })}
              </Table.Tbody>
            </Table>
          </ContentBox>
        </PagePanel.Wrapper>
      )}
      renderUnset={() => (
        <VStack>
          <Splash
            title={intl.formatMessage({
              defaultMessage: "Vous n’avez aucune tâches en cours.",
            })}
            image="add"
          />
        </VStack>
      )}
    />
  );
}
